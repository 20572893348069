import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 132px;
  width: 72%;
  padding: 132px 20px 0 20px;
  margin: auto;

  @media (max-width: 1440px) {
    width: 90%;
  }

  @media (max-width: 1370px) {
    padding-top: 163px;
  }

  @media (max-width: 930px) {
    padding-top: 113.48px;
  }

  @media (max-width: 750px) {
    width: 100%;
  }

  @media (max-width: 405px) {
    padding-top: 121.14px;
  }

  h2 {
    border-bottom: 8px solid #2980b9;
    padding: 0px 70px 5px 0px;
    position: relative;
    margin-bottom: 120px;
    align-self: flex-start;
    z-index: 1;
    color: #0e5072;
    margin-left: 50px;
    margin-top: 100px;

    @media (max-width: 1000px) {
      margin-bottom: 60px;
    }

    @media (max-width: 750px) {
      padding: 0px 40px 5px 0px;
      margin-top: 50px;
      margin-left: 0px;
    }

    img {
      position: absolute;
      top: -70px;
      left: -110px;
      z-index: 0;

      @media (max-width: 750px) {
        width: 130px;
        height: auto;
        top: -45px;
        left: -65px;
      }
    }
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    grid-gap: 65px;
    width: 100%;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 46%);
    }

    @media (max-width: 750px) {
      grid-gap: 40px;
    }

    @media (max-width: 550px) {
      grid-template-columns: 100%;
      grid-gap: 50px;
    }

    > a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      :hover {
        button {
          background-color: #2980b9;
        }
      }

      .imagem {
        width: 100%;
        height: 280px;
        border-radius: 16px;

        @media (max-width: 400px) {
          height: 220px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 16px;
        }
      }

      .texto {
        display: flex;
        margin-top: 35px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 10px;
        margin-bottom: 50px;

        @media (max-width: 750px) {
          margin-bottom: 30px;
        }

        .data {
          color: #45555c;
          max-width: unset;
          font-weight: 400;
          margin-right: 15px;
        }

        p {
          margin: 0;
          color: #0e5072;
          font-size: 22px;
          line-height: 24px;
          font-weight: bold;

          @media (max-width: 750px) {
            font-size: 18px;
            line-height: 20px;
          }
        }
      }

      button {
        margin-top: 0;
        font-weight: 500;
        font-size: 18px;
        color: #fff;
        padding: 10px 25px;
        background-color: #0bb4ff;
        border: none;
        transition: all ease 0.5s;
        cursor: pointer;
      }
    }
  }

  .ant-pagination {
    margin: 100px 0 200px 0;
    @media (max-width: 750px) {
      margin: 100px 0;
    }
  }

  .ant-pagination-item {
    width: 50px;
    height: 50px;

    a {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    width: 50px;
    height: 50px;
  }
`;

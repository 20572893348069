import React, { useState } from 'react';
// import { AiOutlineYoutube } from 'react-icons/ai';
import { FaPhone } from 'react-icons/fa';

import { Navbar } from './style.js';

export default function Header(props) {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <Navbar isOpen={openMenu}>
        <div className="topheader">
          <div>
            <div className="social">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://pt-br.facebook.com/vaskupienclinica/"
              >
                <img
                  src={require('../../assets/img/facebook.svg')}
                  alt="Facebook Icon"
                />
              </a>
              {/* <a href="/">
                <img
                  src={require('../../assets/img/instagram.svg')}
                  alt="Instagram Icon"
                />
              </a>
              <a href="/">
                <img
                  src={require('../../assets/img/linkedin.svg')}
                  alt="LinkedIn Icon"
                />
              </a> */}
            </div>
            <div className="info">
              <a className="tel" href="tel:+554230289191">
                <span>42</span> <p>3028-9191</p>
                <FaPhone />
              </a>
              <a
                className="endereco"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/Vaskupien+Cl%C3%ADnica+Vascular/@-25.1033063,-50.1588257,15z/data=!4m2!3m1!1s0x0:0x87bbb1d9a6167648?sa=X&ved=2ahUKEwilrZakooTrAhXfHrkGHYyMAZ4Q_BIwCnoECBMQCA"
              >
                R. Nestor Guimarães, 77, sala 104
              </a>
              <a
                className="mapa"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/Vaskupien+Cl%C3%ADnica+Vascular/@-25.1033063,-50.1588257,15z/data=!4m2!3m1!1s0x0:0x87bbb1d9a6167648?sa=X&ved=2ahUKEwilrZakooTrAhXfHrkGHYyMAZ4Q_BIwCnoECBMQCA"
              >
                ver no mapa
                <div className="maps">
                  <img
                    src={require('../../assets/img/google-maps.svg')}
                    alt="Google Maps Icon"
                  />
                </div>
                <div />
              </a>
            </div>
          </div>
        </div>
        <div className="navbar">
          <div>
            <a href="/" className="logo">
              <img
                src={require('../../assets/logo.svg')}
                alt="Logo Vaskupien"
              />
            </a>
            <div>
              <nav>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/#sobre" onClick={() => setOpenMenu(false)}>
                      Sobre
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#especialidades"
                      onClick={() => setOpenMenu(false)}
                    >
                      Especialidades
                    </a>
                  </li>
                  <li>
                    <a href="/#estrutura" onClick={() => setOpenMenu(false)}>
                      Estrutura
                    </a>
                  </li>
                  <li>
                    <a href="/#blog" onClick={() => setOpenMenu(false)}>
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="/#depoimentos" onClick={() => setOpenMenu(false)}>
                      Depoimentos
                    </a>
                  </li>
                  <li>
                    <a href="#contato" onClick={() => setOpenMenu(false)}>
                      Contato
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="agendar">
                <p>Agendar:</p>
                <div className="links">
                  <a
                    href="/#consulta"
                    onClick={() => {
                      setOpenMenu(false);
                      props.callbackParent('Consulta');
                    }}
                  >
                    Consulta
                  </a>
                  <a
                    href="/#consulta"
                    onClick={() => {
                      setOpenMenu(false);
                      props.callbackParent('Exame');
                    }}
                  >
                    Exame
                  </a>
                  {/* <a
                    href="/#consulta"
                    className="telemedicina"
                    onClick={() => setOpenMenu(false)}
                  >
                    Telemedicina
                  </a> */}
                  <a
                    href="https://api.whatsapp.com/send?phone=554230289191"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whats"
                  >
                    <img
                      src={require('../../assets/img/Whats.svg')}
                      alt="Whatsapp Icon"
                    />
                  </a>
                </div>
              </div>
            </div>
            <button
              className={`hamburger hamburger--collapse ${openMenu ? 'is-active' : ''
                }`}
              type="button"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
      </Navbar>
    </>
  );
}

import styled from 'styled-components';
import depoimentos_bg from '../../assets/img/depoimentos-bg.svg';

export const Secao = styled.section`
  width: 100%;
  position: relative;
  padding: 0 20px;
  padding-top: 150px;
  margin-top: -150px;

  @media (max-width: 1370px) {
    padding-top: 182px;
    margin-top: -182px;
  }

  @media (max-width: 930px) {
    padding-top: 122px;
    margin-top: -122px;
  }

  @media (max-width: 580px) {
    padding-top: 117px;
    margin-top: -117px;
  }

  @media (max-width: 405px) {
    padding-top: 125px;
    margin-top: -125px;
  }

  @media (max-width: 360px) {
    padding-right: 10px;
    padding-left: 10px;
  }

  #bg {
    width: 100%;
    height: 50%;
    background-color: #4d4d4d;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .content {
    width: 85%;
    background-color: #2980b9;
    border-radius: 20px;
    padding: 130px 20px 115px 20px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1440px) {
      width: 95%;
    }

    @media (max-width: 1285px) {
      width: 100%;
    }

    @media (max-width: 750px) {
      padding: 60px 10px 60px 10px;
    }

    @media (max-width: 360px) {
      padding: 60px 5px 60px 5px;
    }

    .title {
      width: 75%;
      background-image: url(${depoimentos_bg});
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 35px 0;
      margin-bottom: 20px;

      @media (max-width: 1005px) {
        width: 95%;
      }

      @media (max-width: 750px) {
        width: 100%;
      }

      h2 {
        padding: 0px 35px 20px 35px;
        margin: auto;

        @media (max-width: 750px) {
          padding: 0px 0px 20px 0px;
        }
      }
    }

    .filtre {
      font-size: 22px;
      line-height: 36px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 20px;

      @media (max-width: 750px) {
        font-size: 18px;
        line-height: 32px;
      }
    }

    .filtros {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;

      button {
        padding: 20px 35px;
        margin: 8px 12px;
        border-radius: 14px;
        border: 1px solid #40abf2;
        cursor: pointer;
        width: fit-content;
        background-color: transparent;
        font-size: 32px;
        line-height: 36px;
        color: #40abf2;

        @media (max-width: 750px) {
          padding: 15px 25px;
          margin: 8px;
          font-size: 24px;
          line-height: 28px;
          width: 115px;
          padding: 20px 0px;
        }
      }

      .active {
        border: 1px solid #0bffec;
        color: #0bffec;
      }
    }

    .carousel {
      width: 85%;
      height: fit-content;
      margin-top: 65px;

      @media (max-width: 1500px) {
        width: 95%;
      }

      @media (max-width: 750px) {
        width: 100%;
        margin-top: 50px;
      }

      .slick-slide {
        margin-bottom: 140px;

        @media (max-width: 750px) {
          margin-bottom: 80px;
        }

        > div {
          margin: 0 8px;
        }
      }

      .slick-dots-bottom {
        bottom: 0;

        li,
        button {
          width: 24px;
          height: 24px;
          border-radius: 100%;

          @media (max-width: 750px) {
            width: 20px;
            height: 20px;
          }
        }
      }

      .slider {
        padding: 25px;
        border: 1px solid #ffffff;
        border-radius: 14px;

        .aspas {
          font-size: 56px;
          color: #0bffec;
          font-weight: 500;
          line-height: 56px;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          color: #fff;
          line-height: 26px;
          margin-bottom: 30px;
        }

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .nome {
            font-size: 22px;
            line-height: 36px;
            font-weight: 500;
            margin-bottom: 0px;
          }

          .nota {
            color: #0bffec;
            font-size: 32px;
            line-height: 36px;
            margin-bottom: 0px;
          }
        }
      }
    }

    .avaliacao {
      margin-top: 100px;
      padding: 55px 20px 65px 20px;
      border: 1px solid #fff;
      width: 85%;
      border-radius: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: 1500px) {
        width: 95%;
      }

      @media (max-width: 750px) {
        width: 100%;
        margin-top: 80px;
      }

      h2 {
        padding-bottom: 20px;
        margin-bottom: 40px;
        width: fit-content;

        @media (max-width: 750px) {
          margin-bottom: 40px;
        }
      }

      form {
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 750px) {
          width: 95%;
        }

        @media (max-width: 515px) {
          width: 100%;
        }

        .inputs {
          display: flex;
          justify-content: space-between;
          width: 100%;

          @media (max-width: 600px) {
            flex-direction: column;
          }

          > div {
            display: flex;
            flex-direction: column;
            width: 47%;

            @media (max-width: 600px) {
              width: 100%;
            }
          }
        }

        input,
        textarea {
          height: 50px;
          border-radius: 40px;
          background-color: #f8f8f8;
          border: none;
          margin: 13px 0;
          padding: 0 25px;
          font-size: 18px;
          color: #808080;
          font-weight: 300;
          line-height: 32px;
          font-family: 'Cera Pro';

          @media (max-width: 750px) {
            font-size: 16px;
            line-height: 30px;
          }
        }

        input {
          width: 100%;
        }

        textarea {
          width: 100%;
          resize: none;
          padding: 15px 25px;
          border-radius: 20px;
          height: 150px;
        }

        p {
          font-size: 30px;
          color: #fff;
          line-height: 51px;
          font-weight: bold;
          margin-top: 40px;
          text-align: center;
          margin-bottom: 25px;

          @media (max-width: 750px) {
            font-size: 24px;
            line-height: 30px;
            margin-top: 25px;
          }
        }

        .notas {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 60px;

          @media (max-width: 1020px) {
            flex-direction: column;
          }

          @media (max-width: 750px) {
            margin-bottom: 40px;
          }

          > div {
            display: flex;
          }

          button {
            width: 50px;
            height: 50px;
            background-color: rgba(255, 255, 255, 0.5);
            border: 2px solid rgba(255, 255, 255, -0.5);
            border-radius: 100%;
            font-size: 26px;
            line-height: 30px;
            color: #2980b9;
            margin: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all ease 0.5s;

            @media (max-width: 400px) {
              width: 45px;
              height: 45px;
              font-size: 24px;
              line-height: 28px;
              margin: 4px;
            }

            :hover {
              border: 2px solid #0bffec;
              color: #0bffec;

              @media (max-width: 750px) {
                color: #2980b9;
              }
            }
          }

          .active {
            background-color: #0bffec;
            color: #2980b9;
          }
        }

        .autorizacao {
          p {
            margin-top: 0;
          }
          > div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 50px;

            > div {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 15px;

              label {
                font-size: 32px;
                line-height: 36px;
                color: #fff;
                cursor: pointer;

                @media (max-width: 750px) {
                  font-size: 26px;
                  line-height: 30px;
                }
              }
            }
          }

          .send-btn {
            width: fit-content;
            position: relative;
          }

          button {
            width: 40px;
            height: 40px;
            background-color: #fff;
            border-radius: 100%;
            margin-right: 10px;
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.5);
            border: 2px solid rgba(255, 255, 255, -0.5);
            display: flex;
            justify-content: center;
            align-items: center;

            .check {
              width: 25px;
              height: 25px;
              border-radius: 100%;
              /* position: absolute; */
              background-color: #0bffec;
            }
          }
        }

        .enviar {
          padding: 15px 60px;
          background-color: #135681;
          border-radius: 40px;
          font-size: 26px;
          line-height: 38px;
          color: #fff;
          cursor: pointer;
          transition: all ease 0.5s;
          border: none;

          :hover {
            background-color: #0bb4ff;
          }

          @media (max-width: 750px) {
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
  }
`;

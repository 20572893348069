import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './style';
import { Pagination } from 'antd';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import api from '../../services/api';
import smoothScroll from '../../utils/smoothScroll';

export default function Blog() {
  const [posts, setPosts] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    api.get('/wp/v2/posts?_embed&per_page=9').then((response) => {
      setPosts(response.data);
      setTotal(response.headers['x-wp-total']);
      setLoading(false);
    });
  }, []);

  function paginate(page) {
    api.get(`/wp/v2/posts?_embed&per_page=9&page=${page}`).then((response) => {
      setPosts(response.data);
    });
  }
  function scrollTo(element) {
    smoothScroll(element);
  }

  return (
    <>
      <Helmet>
        <title>Blog - Vaskupien | Clínica Vascular</title>
        <meta
          name="description"
          content="A evolução da medicina torna obrigatória a evolução de quem se dedica à ela. Assim, com dedicação e refinamento de nossas técnicas, hoje, agredimos menos e tratamos melhor."
        />
        <meta
          name="keywords"
          content="medicina, clínica, vascular, tratamento, cirurgia"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>
      <Header />
      <Container id="top">
        <h2>
          <img
            src={require('../../assets/img/title-detail.svg')}
            alt="Detalhe"
          />
          Blog
        </h2>
        <div className={loading ? 'loading' : ''}>
          <div className="loader"></div>
        </div>
        <div className={`cards ${loading ? 'dontdisplay' : ''}`}>
          {!!posts &&
            posts.map((post) => (
              <a href={`/blog/${post.slug}`} key={Math.random()}>
                <div className="imagem">
                  <img
                    src={post._embedded['wp:featuredmedia'][0].source_url}
                    alt=""
                  />
                </div>
                <div className="texto">
                  <p>{post.title.rendered}</p>
                </div>
                <button>Saiba mais</button>
              </a>
            ))}
        </div>

        <Pagination
          pageSize={9}
          total={total}
          onChange={(page) => {
            scrollTo('#top');
            paginate(page);
          }}
        />
      </Container>
      <Footer />
    </>
  );
}

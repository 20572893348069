import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import api from '../services/api';

export default function Teste() {
  useEffect(() => {
    axios
      .get(
        'https://painel.vaskupien.com.br/wp-json/wp/v2/pages?slug=home&_fields=acf.servicos'
      )
      .then((response) => {
        console.log(response.data);
      });
  }, []);

  return <div>teste</div>;
}

import styled from 'styled-components';
import certificados_bg from '../../assets/img/certificados-bg.png';
import aspas from '../../assets/img/aspas.svg';
import traco from '../../assets/img/traço.jpg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 132px;

  @media (max-width: 1370px) {
    padding-top: 163px;
  }

  @media (max-width: 930px) {
    padding-top: 113.48px;
  }

  @media (max-width: 405px) {
    padding-top: 121.14px;
  }
`;

export const Banner = styled.section`
  height: calc(100vh - 132px);
  width: 100%;
  position: relative;
  overflow: hidden;
  /* min-height: 680px; */

  @media (max-width: 1370px) {
    height: calc(100vh - 163px);
  }

  @media (max-width: 930px) {
    height: calc(100vh - 113.48px);
  }

  @media (max-width: 745px) {
    height: 500px;
  }

  .btns {
    position: absolute;
    z-index: 1;
    top: 23vh;
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin: 0 15%;

    @media (max-width: 1440px) {
      width: 85%;
      margin: 0 7.5%;
    }

    @media (max-width: 980px) {
      width: 165px;
      margin: 0;
      padding: 0 20px;
      top: 30px;
    }

    @media (max-width: 750px) {
      width: 130px;
      top: 20px;
    }

    button {
      width: 55px;
      height: 55px;
      border: none;
      background-color: rgba(14, 80, 114, 0.1);
      border-radius: 100%;
      cursor: pointer;
      transition: all ease 0.5s;
      display: flex;
      align-items: center;
      justify-content: center;

      :hover {
        background-color: rgba(14, 80, 114, 0.5);
      }

      @media (max-width: 750px) {
        width: 40px;
        height: 40px;
      }

      svg {
        transform: scale(3);
        fill: #fff;

        @media (max-width: 750px) {
          transform: scale(2);
        }
      }
    }
  }

  .ant-carousel,
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
    width: 100%;

    > div {
      height: 100%;
      width: 100%;
    }
  }

  .slick-dots {
    bottom: 30vh;
    max-width: 60%;
    margin: 0 auto;

    @media (max-width: 750px) {
      bottom: 120px;
    }

    li,
    button {
      width: 24px !important;
      height: 24px !important;
      border-radius: 100% !important;
    }
  }

  .ant-carousel {
    .slider {
      position: relative;
      height: 100%;
      overflow: hidden;
    }

    .video-container {
      height: 100%;
      background-image: url(null);
      background-size: cover;
      background-position: center center;

      @media (max-width: 745px) {
        display: none;
      }

      > video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: 100vw;
      }
    }

    > div {
      position: relative;

      .imagem {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        .overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(235, 251, 255, 0.7);
          z-index: 0;
          top: 0;
          left: 0;
        }
      }

      .texto {
        width: 60%;
        height: 47vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: auto;

        @media (max-width: 1440px) {
          width: 70%;
        }

        @media (max-width: 980px) {
          width: 96%;
        }

        @media (max-width: 750px) {
          height: 400px;
        }

        h4 {
          font-size: 24px;
          line-height: 50px;
          font-weight: 500;
          color: #45555c;
          margin-bottom: 10px;
          padding: 0 13% 0 35px;
          background-color: #fff;
          width: fit-content;
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;

          @media (max-width: 1440px) {
            font-size: 21px;
            line-height: 47px;
          }

          @media (max-width: 750px) {
            font-size: 18px;
            line-height: 44px;
            padding: 0 7% 0 15px;
          }

          ::after {
            content: '';
            width: 85%;
            height: 8px;
            position: absolute;
            background-color: #2980b9;
            left: 75%;

            @media (max-width: 750px) {
              display: none;
            }
          }
        }

        h2 {
          border-bottom: none;
          text-align: start;
          color: #45555c;
          background-color: #fff;
          font-weight: bold;
          padding: 15px 30px;
          width: fit-content;
          margin: 0;
          letter-spacing: -2px;

          @media (max-width: 1440px) {
            font-size: 46px;
            line-height: 41px;
          }

          @media (max-width: 750px) {
            font-size: 32px;
            line-height: 27px;
            padding: 10px 15px;
          }
        }
      }
    }
  }
`;

export const Agende = styled.section`
  width: 100%;
  padding-top: 152px;
  margin-top: -152px;
  position: relative;
  top: -28vh;

  @media (max-width: 1370px) {
    padding-top: 182px;
    margin-top: -182px;
  }

  @media (max-width: 930px) {
    padding-top: 122px;
    margin-top: -122px;
  }

  @media (max-width: 750px) {
    top: -100px;
  }

  @media (max-width: 580px) {
    padding-top: 117px;
    margin-top: -117px;
  }

  @media (max-width: 405px) {
    padding-top: 125px;
    margin-top: -125px;
  }

  span {
    font-size: 15px;
    color: red;
    font-weight: bold;
  }

  > div {
    width: 77%;
    background-color: #2980b9;
    border-radius: 16px;
    margin: auto;
    padding: 60px 20px 85px 20px;
    position: relative;
    z-index: 1;

    @media (max-width: 1440px) {
      width: 90%;
    }

    @media (max-width: 980px) {
      width: 96%;
    }

    @media (max-width: 750px) {
      padding: 40px 20px 65px 20px;
    }
  }

  .consulta,
  .contato {
    width: 83%;
    margin: auto;

    @media (max-width: 1170px) {
      width: 95%;
    }

    @media (max-width: 980px) {
      width: 100%;
    }
  }

  h3 {
    font-size: 32px;
    line-height: 63px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 15px;

    @media (max-width: 750px) {
      font-size: 26px;
      line-height: 26px;
    }
  }

  p {
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 35px;

    @media (max-width: 750px) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 25px;
    }
  }

  .contato {
    width: 85%;
    margin: auto;
    padding-top: 50px;
    border-top: 1px solid #fff;
    margin-top: 75px;

    @media (max-width: 1525px) {
      width: 95%;
    }

    @media (max-width: 1030px) {
      width: 100%;
    }

    @media (max-width: 750px) {
      padding-top: 40px;
      margin-top: 50px;
    }

    p {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      max-width: 860px;
      margin: auto;

      @media (max-width: 750px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 40px;

      @media (max-width: 750px) {
        margin-top: 20px;
      }

      .inputs {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 875px) {
          flex-direction: column;
          align-items: center;
        }

        > div {
          display: flex;
          flex-direction: column;
          width: 30%;

          @media (max-width: 875px) {
            width: 50%;
          }

          @media (max-width: 550px) {
            width: 100%;
          }
        }
      }

      input {
        width: 100%;
        height: 50px;
        border-radius: 40px;
        background-color: #f8f8f8;
        border: none;
        font-size: 20px;
        font-weight: 300;
        color: #808080;
        line-height: 34px;
        padding: 0 25px;

        @media (max-width: 875px) {
          margin: 10px auto;
        }

        @media (max-width: 750px) {
          font-size: 16px;
          line-height: 30px;
        }
      }

      .submitbtn {
        width: fit-content;
        position: relative;
        margin: 20px auto 0 auto;

        @media (max-width: 875px) {
          margin-top: 10px;
        }
      }

      button {
        font-size: 24px;
        line-height: 36px;
        color: #fff;
        font-weight: 500;
        padding: 0px 40px;
        border-radius: 40px;
        border: none;
        background-color: #0bb4ff;
        height: 50px;
        cursor: pointer;
        transition: all ease 0.5s;
        width: fit-content;

        @media (max-width: 875px) {
          width: 50%;
          margin-top: 10px;
        }

        @media (max-width: 750px) {
          font-size: 20px;
          line-height: 22px;
        }

        @media (max-width: 550px) {
          width: 100%;
        }

        :hover {
          background-color: #fff;
          color: #2980b9;
        }
      }
    }
  }

  .agenda {
    .buttons {
      display: flex;
      align-items: flex-end;
    }
    .item {
      font-size: 26px;
      line-height: 36px;
      font-weight: bold;
      color: #398eba;
      padding: 25px 55px;
      border: none;
      background-color: #0e5072;
      border-radius: 16px 16px 0px 0px;
      transition: all ease 0.5s;
      cursor: pointer;

      p {
        font-size: 26px;
        line-height: 36px;
        font-weight: bold;
        color: #398eba;
        margin: 0;

        @media (max-width: 750px) {
          font-size: 20px;
          line-height: 20px;
        }
      }

      @media (max-width: 750px) {
        font-size: 20px;
        line-height: 20px;
        padding: 20px 30px;
      }

      @media (max-width: 489px) {
        width: 50%;
        padding: 15px 5px;
        height: 100%;
      }
    }

    .item:nth-child(2) {
      @media (max-width: 416px) {
        height: 70px;
      }
    }

    .active {
      color: #b5b5b5;
      background-color: #fff;
      border-bottom: 1px solid #dbdbdb;
    }
  }

  .horarios {
    display: none;
    transition: all ease 0.5s;
  }

  .showHorariosConsulta {
    display: inline-flex !important;
    width: 100%;
    background-color: #fff;
    border-radius: 0 16px 16px 16px;
    padding: 35px 0 40px 0;
    position: relative;

    @media (max-width: 750px) {
      padding: 25px 0 30px 0;
    }

    @media (max-width: 489px) {
      border-radius: 0 0px 16px 16px;
    }
  }

  .dontshow {
    display: none !important;
  }

  .next {
    right: -20px;
    left: unset !important;
  }

  .slider-btn {
    position: absolute;
    top: 45%;
    z-index: 1;
    width: 55px;
    height: 55px;
    border: none;
    background-color: #45555c;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: -20px;

    @media (max-width: 650px) {
      width: 40px;
      height: 40px;
    }

    svg {
      transform: scale(3);
      fill: #fff;

      @media (max-width: 650px) {
        transform: scale(2);
      }
    }
  }

  /* .btns {

    display: flex;
    justify-content: space-between;
    width: calc(100% + 55px);
    left: -27.5px;

    @media (max-width: 650px) {
      width: calc(100% + 40px);
      left: -20px;
    }

    button {
      
    }
  } */

  .carousel {
    width: 100%;
  }

  .ant-carousel {
    > div {
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        h4 {
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          color: #45555c;
          margin: 0;
        }

        p {
          font-size: 16px;
          line-height: 21px;
          color: #45555c;
          margin: 0;
        }
      }

      .horario {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 55px 0 55px;
        border-right: 1px solid #dbdbdb;
        border-radius: 0;

        @media (max-width: 750px) {
          padding: 25px 50px 0 50px;
        }

        button {
          margin-bottom: 25px;
          width: fit-content;
          border: none;
          background-color: #eef4fd;
          padding: 20px 35px;
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          color: #0bb4ff;
          border-radius: 8px;
          cursor: pointer;
          transition: all ease 0.5s;

          @media (max-width: 750px) {
            font-size: 16px;
            line-height: 22px;
            padding: 15px 30px;
          }

          :hover {
            background-color: #6896db;
            color: #fff;
          }
        }

        button:disabled {
          background-color: #fff;
          color: transparent;
          z-index: -1;
          background-image: url(${traco});
          background-repeat: no-repeat;
          background-position: center;
          background-size: 9px 3px;
        }

        p {
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          color: #008ccb;
        }
      }
    }
  }

  .show {
    z-index: 2 !important;
    display: inline-flex !important;
  }

  .modal {
    display: none;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: -1;
    background-color: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 0 16px 16px 16px;
    padding: 35px 55px 40px 55px;

    @media (max-width: 1100px) {
      padding: 35px 40px 40px 40px;
    }

    @media (max-width: 750px) {
      padding: 35px 20px 40px 20px;
    }

    @media (max-width: 489px) {
      border-radius: 0 0px 16px 16px;
    }

    @media (max-width: 400px) {
      padding: 35px 10px 40px 10px;
    }

    h2 {
      font-size: 40px;
      line-height: 40px;
      font-weight: bold;
      color: #45555c;
      margin: 0;
      border: 0;

      @media (max-width: 750px) {
        font-size: 30px;
        line-height: 30px;
        text-align: center;
      }
    }

    p {
      font-size: 30px;
      line-height: 30px;
      color: #45555c;

      @media (max-width: 750px) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    form {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 20px;
      background-color: #6896db;
      width: 100%;
      padding: 50px 20px;

      @media (max-width: 1000px) {
        flex-direction: column;
      }

      @media (max-width: 750px) {
        padding: 30px 20px;
      }

      @media (max-width: 400px) {
        padding: 30px 10px;
      }

      > div {
        width: 40%;

        @media (max-width: 1100px) {
          width: 45%;
        }

        @media (max-width: 1000px) {
          width: 90%;
        }

        @media (max-width: 500px) {
          width: 100%;
        }
      }

      input {
        width: 100%;
        height: 50px;
        margin: 12.5px 0;
        border-radius: 40px;
        background-color: #f8f8f8;
        border: none;
        font-size: 20px;
        font-weight: 300;
        color: #808080;
        line-height: 34px;
        padding: 0 25px;

        @media (max-width: 750px) {
          font-size: 16px;
          line-height: 30px;
          margin: 10px 0;
        }
      }

      .infos {
        background-color: #4e71a5;
        border-radius: 20px;
        padding: 30px 40px;

        @media (max-width: 1000px) {
          margin-top: 12.5px;
        }

        @media (max-width: 750px) {
          margin-top: 10px;
          padding: 15px 30px;
        }

        h4 {
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          color: #fff;
          margin-bottom: 30px;

          @media (max-width: 750px) {
            margin-bottom: 20px;
          }
        }

        p {
          font-size: 16px;
          color: #fff;
          line-height: 21px;
          font-weight: 300;
          margin-bottom: 5px;

          strong {
            font-weight: bold;
          }
        }

        .alerta {
          font-weight: bold;
          margin-top: 10px;
        }

        > div {
          display: flex;
          justify-content: space-between;
          margin-top: 40px;
          position: relative;

          @media (max-width: 750px) {
            margin-top: 30px;
          }

          > div {
            display: none;
          }

          button {
            width: 47%;
            border: none;
            border-radius: 40px;
            font-size: 16px;
            font-weight: bold;
            line-height: 21px;
            padding: 10px 0;
            cursor: pointer;
            transition: all ease 0.5s;

            :hover {
              background-color: #0bb4ff;
            }
          }

          .cancelar {
            background-color: #385580;
            color: #fff;
          }

          .confirmar {
            background-color: #2bfae2;
            color: #385580;
          }
        }
      }
    }
  }
`;

export const Sobre = styled.section`
  width: 77%;
  margin: auto;
  margin-bottom: 65px;
  padding: 0 20px;
  padding-top: 152px;
  margin-top: -152px;
  position: relative;

  @media (max-width: 1370px) {
    padding-top: 182px;
    margin-top: -182px;
  }

  @media (max-width: 930px) {
    padding-top: 122px;
    margin-top: -122px;
  }

  @media (max-width: 580px) {
    padding-top: 117px;
    margin-top: -117px;
  }

  @media (max-width: 405px) {
    padding-top: 125px;
    margin-top: -125px;
  }

  @media (max-width: 1440px) {
    width: 90%;
  }

  @media (max-width: 1300px) {
    width: 100%;
  }

  @media (max-width: 1000px) {
    margin-bottom: 0;
  }

  h2 {
    border-bottom: 8px solid #2980b9;
    padding: 0px 70px 5px 0px;
    position: relative;
    margin-bottom: 120px;
    align-self: flex-start;
    z-index: 1;
    color: #0e5072;
    margin-left: 50px;

    @media (max-width: 1000px) {
      margin-bottom: 60px;
    }

    @media (max-width: 750px) {
      padding: 0px 40px 5px 0px;
      margin-bottom: 30px;
      margin-left: 0px;
    }

    img {
      position: absolute;
      top: -70px;
      left: -110px;
      z-index: 0;

      @media (max-width: 750px) {
        width: 130px;
        height: auto;
        top: -45px;
        left: -65px;
      }
    }
  }

  .top-text {
    position: relative;
    z-index: 0;

    > div {
      display: flex;
      justify-content: space-between;
      position: relative;

      .blue-bg {
        width: 27%;
        height: 580px;
        background-color: #2980b9;
        border-radius: 20px;

        @media (max-width: 1000px) {
          display: none;
        }
      }

      .text {
        width: 67%;
        display: flex;
        flex-direction: column;
        padding-top: 60px;

        @media (max-width: 1000px) {
          width: 100%;
        }

        @media (max-width: 750px) {
          padding-top: 30px;
        }
      }

      p {
        font-size: 30px;
        line-height: 60px;
        color: #45555c;
        font-weight: 300;
        background-image: url(${aspas});
        background-repeat: no-repeat;
        background-position: 40px top;

        @media (max-width: 750px) {
          font-size: 20px;
          line-height: 40px;
        }
      }
    }

    .imagem {
      position: absolute;
      bottom: -255px;
      left: 220px;
      width: 60%;
      display: flex;
      height: fit-content;

      @media (max-width: 1000px) {
        position: relative;
        bottom: 0;
        left: 0;
        margin: auto;
        margin-top: 30px;
      }

      @media (max-width: 750px) {
        width: 90%;
      }

      @media (max-width: 400px) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .bottom-text {
    position: relative;
    z-index: 1;
    margin-top: 155px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1000px) {
      margin-top: 0;
      top: -60px;
      margin-bottom: 0;
      flex-direction: column;
    }

    @media (max-width: 400px) {
      top: -10px;
    }

    .imagem {
      display: flex;
      width: 52%;
      position: relative;
      height: fit-content;

      @media (max-width: 1000px) {
        width: 70%;
      }

      @media (max-width: 530px) {
        width: 90%;
      }

      img {
        width: 83%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }

      ::after {
        position: relative;
        width: 125px;
        height: 385px;
        background-color: #2980b9;
        content: '';
        top: 100px;
        border-bottom-right-radius: 20px;

        @media (max-width: 1000px) {
          top: 60px;
          height: 250px;
        }

        @media (max-width: 400px) {
          top: 10px;
          height: 170px;
          right: 10px;
          z-index: -1;
        }
      }
    }

    .text {
      margin-top: 195px;
      width: 41%;

      @media (max-width: 1000px) {
        width: 100%;
        margin-top: 50px;
      }

      > div {
        margin-bottom: 50px;

        @media (max-width: 750px) {
          margin-bottom: 30px;
        }
      }

      .nome {
        color: #2980B9;
        font-weight: bold;
      }

      p {
        font-size: 20px;
        line-height: 40px;
        color: #45555c;
        font-weight: 300;

        @media (max-width: 750px) {
          font-size: 16px;
          line-height: 32px;
          margin-bottom: 30px;
        }
      }
    }
  }
`;

export const Formacao = styled.section`
  width: 72%;
  margin: auto;
  margin-bottom: 65px;
  padding: 0 20px;

  @media (max-width: 1440px) {
    width: 90%;
  }

  @media (max-width: 1100px) {
    width: 100%;
  }

  h2 {
    border-bottom: 8px solid #2980b9;
    padding: 0px 75px 5px 0px;
    position: relative;
    margin-bottom: 55px;
    align-self: flex-start;
    z-index: 1;
    color: #0e5072;

    @media (max-width: 750px) {
      margin-bottom: 35px;
      padding: 0px 40px 5px 0px;
    }
  }

  > div {
    display: grid;
    grid-template-columns: repeat(3, 33%);

    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 48%);
    }

    @media (max-width: 550px) {
      grid-template-columns: 100%;
    }

    > div {
      padding: 50px 0 55px 0;
      border-right: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;

      @media (max-width: 750px) {
        padding: 30px 0 35px 0;
      }

      @media (max-width: 550px) {
        border-right: none !important;
      }

      p {
        max-width: 85%;
        font-size: 20px;
        line-height: 40px;
        color: #45555c;
        margin: 0 auto;
        font-weight: 300;

        @media (max-width: 750px) {
          font-size: 16px;
          line-height: 32px;
        }

        strong {
          font-weight: bold;
        }
      }
    }

    div:nth-child(2) {
      @media (max-width: 1000px) {
        border-right: none;
      }
    }

    div:nth-child(3) {
      border-right: none;

      @media (max-width: 1000px) {
        border-right: 1px solid #ebebeb;
      }
    }

    div:nth-child(4) {
      @media (max-width: 1000px) {
        border-right: none;
        border-bottom: 1px solid #ebebeb !important;
      }
    }

    div:nth-child(4),
    div:nth-child(5) {
      border-bottom: none;
    }

    div:nth-child(5) {
      @media (max-width: 550px) {
        border-bottom: none;
      }
    }
  }
`;

export const Doencas = styled.section`
  width: 86.25%;
  background-color: #f8f8f8;
  margin-bottom: 340px;
  align-self: flex-start;
  padding: 130px 20px 120px 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: -1;
  position: relative;

  @media (max-width: 1210px) {
    width: 94%;
  }

  @media (max-width: 1110px) {
    width: 98%;
  }

  @media (max-width: 750px) {
    margin-bottom: 200px;
    padding: 70px 20px 60px 20px;
  }

  > div {
    width: 85%;
    margin-left: auto;

    @media (max-width: 1440px) {
      width: 94%;
    }

    @media (max-width: 750px) {
      width: 100%;
    }
  }

  h2 {
    border-bottom: 8px solid #2980b9;
    padding: 0px 125px 5px 0px;
    position: relative;
    margin-bottom: 60px;
    align-self: flex-start;
    z-index: 1;
    color: #0e5072;

    @media (max-width: 750px) {
      padding: 0px 0px 5px 0px;
    }

    img {
      position: absolute;
      top: -70px;
      left: -110px;
      z-index: 0;

      @media (max-width: 750px) {
        width: 130px;
        height: auto;
        top: -45px;
        left: -65px;
      }
    }
  }

  .doencas {
    display: grid;
    grid-template-columns: repeat(3, 33%);

    @media (max-width: 1110px) {
      grid-template-columns: repeat(2, 48%);
    }

    @media (max-width: 580px) {
      grid-template-columns: 100%;
    }

    p {
      font-size: 20px;
      line-height: 40px;
      color: #45555c;
      font-weight: 300;
      margin: 0;
      padding-right: 20px;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 36px;
      }
    }
  }
`;

export const Especialidades = styled.section`
  width: 100%;
  background-color: #f8f8f8;
  padding: 152px 20px 90px 20px;
  margin-top: -152px;
  position: relative;

  @media (max-width: 1370px) {
    padding-top: 182px;
    margin-top: -182px;
  }

  @media (max-width: 930px) {
    padding-top: 122px;
    margin-top: -122px;
  }

  @media (max-width: 580px) {
    padding-top: 117px;
    margin-top: -117px;
  }

  @media (max-width: 405px) {
    padding-top: 125px;
    margin-top: -125px;
  }

  > div {
    width: 72%;
    margin: auto;

    @media (max-width: 1600px) {
      width: 90%;
    }

    @media (max-width: 950px) {
      width: 100%;
    }
  }

  h2 {
    border-bottom: 8px solid #2980b9;
    padding: 20px 125px 5px 0px;
    position: relative;
    align-self: flex-start;
    z-index: 1;
    margin-bottom: 150px;
    color: #0e5072;

    @media (max-width: 750px) {
      margin-bottom: 70px;
      padding: 20px 0px 5px 0px;
    }

    img {
      position: absolute;
      top: -70px;
      left: -110px;
      z-index: 0;

      @media (max-width: 750px) {
        width: 130px;
        height: auto;
        top: -45px;
        left: -65px;
      }
    }
  }

  p {
    font-size: 30px;
    line-height: 60px;
    color: #45555c;
    font-weight: 300;

    @media (max-width: 750px) {
      font-size: 20px;
      line-height: 40px;
    }
  }

  .rqe {
    display: grid;
    grid-template-columns: repeat(4, 23%);
    grid-row-gap: 20px;
    grid-column-gap: 15px;
    justify-content: center;

    @media (max-width: 1250px) {
      grid-template-columns: repeat(3, 30%);
    }

    @media (max-width: 800px) {
      grid-template-columns: repeat(2, 48%);
    }

    @media (max-width: 550px) {
      grid-template-columns: 100%;
    }

    div:last-child {
      height: unset;

      @media (max-width: 1250px) {
        display: none;
      }

      @media (max-width: 800px) {
        display: inline-block;
        grid-area: 1 / 2;
      }

      @media (max-width: 550px) {
        display: none;
      }
    }

    > div {
      background-color: #fff;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 200px;

      @media (max-width: 550px) {
        height: 150px;
      }

      img {
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 20px;

        @media (max-width: 1600px) {
          width: 435px;
        }

        @media (max-width: 950px) {
          width: 355px;
        }

        @media (max-width: 550px) {
          position: relative;
          width: 100%;
          top: 0;
        }
      }

      p {
        font-size: 20px;
        line-height: 20px;
        font-weight: bold;
        color: #0e5072;
        margin: 0;
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        padding-bottom: 15px;
        min-height: 55px;

        @media (max-width: 750px) {
          font-size: 16px;
          line-height: 18px;
        }

        :after {
          content: '';
          width: 65px;
          height: 8px;
          background-color: #2980b9;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  .especialidades {
    .texto-divisao {
      margin: 20px 0;
    }

    .cards {
      display: grid;
      grid-template-columns: repeat(4, 23%);
      grid-row-gap: 20px;
      justify-content: center;
      grid-column-gap: 15px;

      @media (max-width: 1250px) {
        grid-template-columns: repeat(3, 30%);
      }

      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 48%);
      }

      @media (max-width: 550px) {
        grid-template-columns: 100%;
      }

      div:last-child {
        display: none;

        @media (max-width: 550px) {
          display: inline-block;
          width: 100%;
          height: auto;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 20px;
          }
        }
      }

      > div {
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 200px;

        @media (max-width: 550px) {
          height: 150px;
        }

        p {
          font-size: 20px;
          line-height: 20px;
          font-weight: bold;
          color: #0e5072;
          margin: 0;
          width: 70%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          position: relative;
          padding-bottom: 15px;
          min-height: 55px;

          @media (max-width: 750px) {
            font-size: 16px;
            line-height: 18px;
          }

          :after {
            content: '';
            width: 65px;
            height: 8px;
            background-color: #2980b9;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }

  .blue-detail {
    position: absolute;
    height: 580px;
    width: 400px;
    background-color: #2980b9;
    border-radius: 20px;
    right: -70px;
    top: -445px;
    z-index: -1;

    @media (max-width: 1650px) {
      top: -320px;
    }

    @media (max-width: 750px) {
      display: none;
    }
  }
`;

export const Certificados = styled.section`
  background-image: url(${certificados_bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #0e5072;
  width: 100%;
  padding: 150px 20px 125px 20px;

  @media (max-width: 750px) {
    padding: 100px 20px 80px 20px;
  }

  > div {
    width: 72%;
    margin: auto;

    @media (max-width: 1440px) {
      width: 90%;
    }

    @media (max-width: 750px) {
      width: 100%;
    }
  }

  h2 {
    border-bottom: 8px solid #2980b9;
    padding: 0px 85px 5px 0px;
    position: relative;
    margin-bottom: 120px;
    align-self: flex-start;
    z-index: 1;

    @media (max-width: 750px) {
      margin-bottom: 60px;
      padding: 0px 50px 5px 0px;
    }

    img {
      position: absolute;
      top: -70px;
      left: -110px;
      z-index: 0;

      @media (max-width: 750px) {
        width: 130px;
        height: auto;
        top: -45px;
        left: -65px;
      }
    }
  }

  .certificados {
    display: grid;
    grid-template-columns: repeat(4, 21%);
    grid-gap: 65px;
    width: 100%;
    grid-auto-rows: 250px;
    justify-content: center;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(3, 29%);
    }

    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 46%);
    }

    @media (max-width: 750px) {
      grid-gap: 40px;
    }

    @media (max-width: 550px) {
      grid-template-columns: 100%;
      grid-gap: 50px;
    }

    .imagem {
      display: flex;
      border-radius: 20px;
      cursor: pointer;
      transition: all ease 0.5s;

      :hover {
        transform: scale(1.1);
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        filter: grayscale(100%);
        transition: all ease 0.5s;
      }

      :hover {
        img {
          filter: grayscale(0%);
        }
      }
    }
  }
`;

export const Servicos = styled.section`
  width: 100%;
  background-color: #2980b9;
  padding: 120px 20px 240px 20px;
  margin-bottom: 175px;
  position: relative;

  @media (max-width: 750px) {
    padding: 70px 20px 140px 20px;
    margin-bottom: 100px;
  }

  > div {
    width: 72%;
    margin: auto;

    @media (max-width: 1440px) {
      width: 90%;
    }

    @media (max-width: 1060px) {
      width: 100%;
    }
  }

  h2 {
    border-bottom: 8px solid #0bb4ff;
    padding: 0px 150px 5px 0px;
    position: relative;
    margin-bottom: 150px;
    align-self: flex-start;
    z-index: 1;

    @media (max-width: 750px) {
      margin-bottom: 70px;
      padding: 0px 100px 5px 0px;
    }

    img {
      position: absolute;
      top: -70px;
      left: -110px;
      z-index: 0;

      @media (max-width: 750px) {
        width: 130px;
        height: auto;
        top: -45px;
        left: -65px;
      }
    }
  }

  .servicos {
    display: grid;
    grid-template-columns: repeat(4, 23%);
    grid-row-gap: 50px;
    grid-column-gap: 30px;

    @media (max-width: 950px) {
      grid-template-columns: repeat(3, 31%);
    }

    @media (max-width: 750px) {
      grid-template-columns: repeat(3, 30%);
    }

    @media (max-width: 590px) {
      grid-template-columns: repeat(2, 47%);
    }

    @media (max-width: 430px) {
      grid-template-columns: 100%;
      grid-row-gap: 30px;
    }

    p {
      font-size: 20px;
      font-weight: bold;
      line-height: 20px;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0;
      padding-bottom: 15px;
      position: relative;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 18px;
      }

      @media (max-width: 430px) {
        padding-bottom: 30px;
      }

      :after {
        content: '';
        width: 65px;
        height: 8px;
        background-color: #0bb4ff;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
`;

export const Estrutura = styled.section`
  width: 72%;
  padding: 152px 20px 110px 20px;
  border-bottom: 2px solid #e2e2e2;
  margin-bottom: 200px;
  margin-top: -152px;
  position: relative;

  #detail {
    position: absolute;
    transform: rotateZ(-15deg);
    left: -1240px;
    z-index: -1;
  }

  @media (max-width: 1440px) {
    width: 90%;
  }

  @media (max-width: 1370px) {
    padding-top: 182px;
    margin-top: -182px;
  }

  @media (max-width: 930px) {
    padding-top: 122px;
    margin-top: -122px;
  }

  @media (max-width: 750px) {
    width: 100%;
    margin-bottom: 120px;
    padding-bottom: 80px;
  }

  @media (max-width: 580px) {
    padding-top: 117px;
    margin-top: -117px;
  }

  @media (max-width: 405px) {
    padding-top: 125px;
    margin-top: -125px;
  }

  h2 {
    color: #0e5072;
    border-bottom: 8px solid #2980b9;
    padding: 0px 40px 5px 30px;
    position: relative;
    margin-bottom: 150px;
    align-self: flex-start;

    @media (max-width: 750px) {
      margin-bottom: 70px;
    }

    img {
      position: absolute;
      top: -70px;
      left: -85px;

      @media (max-width: 750px) {
        width: 130px;
        height: auto;
        top: -45px;
        left: -65px;
      }
    }
  }

  > div {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    grid-gap: 65px;
    width: 100%;
    grid-auto-rows: 390px;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 46%);
    }

    @media (max-width: 750px) {
      grid-gap: 40px;
    }

    @media (max-width: 550px) {
      grid-template-columns: 100%;
      grid-gap: 50px;
      grid-auto-rows: 250px;
    }

    .imagem {
      display: flex;
      border-radius: 20px;
      cursor: pointer;
      transition: all ease 0.5s;

      :hover {
        transform: scale(1.1);
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
  }
`;

export const Convenios = styled.section`
  width: 72%;
  padding: 0 20px;
  padding-bottom: 120px;
  border-bottom: 2px solid #e2e2e2;
  margin-bottom: 150px;

  @media (max-width: 1440px) {
    width: 90%;
  }

  @media (max-width: 750px) {
    width: 100%;
    margin-bottom: 100px;
  }

  h2 {
    color: #0e5072;
    border-bottom: 8px solid #2980b9;
    padding: 0px 40px 5px 30px;
    position: relative;
    margin-bottom: 150px;
    align-self: flex-start;
    margin-top: 30px;

    @media (max-width: 750px) {
      margin-bottom: 70px;
    }

    img {
      position: absolute;
      top: -70px;
      left: -85px;

      @media (max-width: 750px) {
        width: 130px;
        height: auto;
        top: -45px;
        left: -65px;
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    div {
      display: flex;
      margin: 15px 20px;

      @media (max-width: 1440px) {
        margin: 15px;
      }

      @media (max-width: 420px) {
        width: 100%;
        max-height: 80px;

        img {
          object-fit: contain;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  p {
    padding: 25px 55px;
    background-color: #2980b9;
    color: #fff;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    max-width: 405px;
    text-align: center;
    margin: auto;
    margin-top: 95px;

    @media (max-width: 750px) {
      font-size: 18px;
      line-height: 18px;
    }

    @media (max-width: 415px) {
      padding: 20px 35px;
    }
  }
`;

export const Blog = styled.section`
  width: 72%;
  padding: 0 20px;
  margin-bottom: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
  margin-top: -150px;

  @media (max-width: 1370px) {
    padding-top: 182px;
    margin-top: -182px;
  }

  @media (max-width: 930px) {
    padding-top: 122px;
    margin-top: -122px;
  }

  @media (max-width: 580px) {
    padding-top: 117px;
    margin-top: -117px;
  }

  @media (max-width: 405px) {
    padding-top: 125px;
    margin-top: -125px;
  }

  @media (max-width: 1440px) {
    width: 90%;
  }

  @media (max-width: 750px) {
    width: 100%;
    margin-bottom: 150px;
  }

  h2 {
    color: #0e5072;
    border-bottom: 8px solid #2980b9;
    padding: 0px 40px 5px 30px;
    position: relative;
    margin-bottom: 150px;
    align-self: flex-start;
    margin-top: 30px;

    @media (max-width: 750px) {
      margin-bottom: 70px;
    }

    img {
      position: absolute;
      top: -70px;
      left: -85px;

      @media (max-width: 750px) {
        width: 130px;
        height: auto;
        top: -45px;
        left: -65px;
      }
    }
  }

  > div {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    grid-gap: 65px;
    width: 100%;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 46%);
    }

    @media (max-width: 750px) {
      grid-gap: 40px;
    }

    @media (max-width: 550px) {
      grid-template-columns: 100%;
      grid-gap: 50px;
    }

    > a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      :hover {
        button {
          background-color: #2980b9;
        }
      }

      .imagem {
        width: 100%;
        height: 280px;
        border-radius: 16px;

        @media (max-width: 400px) {
          height: 220px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 16px;
        }
      }

      .texto {
        display: flex;
        margin-top: 35px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 10px;
        margin-bottom: 50px;

        @media (max-width: 750px) {
          margin-bottom: 30px;
        }

        p {
          margin: 0;
          color: #0e5072;
          font-size: 22px;
          line-height: 24px;
          /* max-width: 250px; */
          font-weight: bold;

          @media (max-width: 750px) {
            font-size: 18px;
            line-height: 20px;
          }
        }
      }

      button {
        margin-top: 0;
        font-weight: 500;
        font-size: 18px;
        color: #fff;
        padding: 10px 25px;
        background-color: #0bb4ff;
        border: none;
        transition: all ease 0.5s;
        cursor: pointer;
      }
    }
  }

  .mais {
    font-size: 26px;
    color: #fff;
    display: flex;
    padding: 10px 25px;
    background-color: #0bb4ff;
    transition: all ease 0.5s;
    margin-top: 100px;
    font-weight: bold;

    @media (max-width: 750px) {
      margin-top: 80px;
      font-size: 24px;
    }

    :hover {
      background-color: #2980b9;
    }
  }
`;

export const Perguntas = styled.section`
  width: 100%;
  margin-bottom: 280px;
  align-self: flex-start;
  margin-left: 15%;
  position: relative;

  @media (max-width: 1440px) {
    margin-left: 7%;
  }

  @media (max-width: 1400px) {
    margin-left: 0;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 950px) {
    padding: 0 20px;
  }

  @media (max-width: 750px) {
    margin-bottom: 120px;
  }

  #detail {
    position: absolute;
    top: -265px;
    right: -180px;
    z-index: -1;

    @media (max-width: 750px) {
      display: none;
    }
  }

  h2 {
    color: #0e5072;
    border-bottom: 8px solid #2980b9;
    padding: 0 185px 5px 30px;
    position: relative;
    margin-bottom: 75px;

    @media (max-width: 875px) {
      padding: 0 30px 5px 30px;
    }

    @media (max-width: 750px) {
      margin-bottom: 50px;
      padding: 0 5px 0 0;
      align-self: flex-start;
    }

    img {
      position: absolute;
      top: -70px;
      left: -85px;

      @media (max-width: 750px) {
        width: 130px;
        height: auto;
        top: -45px;
        left: -65px;
      }
    }
  }

  .ant-collapse {
    max-width: 910px;

    @media (max-width: 950px) {
      max-width: 100%;
    }
  }

  .ant-collapse-item {
    padding: 30px 0px 40px 30px;
    border-bottom: 2px solid #e2e2e2;

    @media (max-width: 750px) {
      padding: 15px 0px 20px 0px;
    }
  }

  .ant-collapse-header {
    font-size: 26px;
    line-height: 24px !important;
    color: #2980b9 !important;
    font-weight: 700;
    padding-right: 65px !important;

    @media (max-width: 750px) {
      font-size: 20px !important;
      line-height: 18px !important;
      padding-right: 25px !important;
    }
  }

  .ant-collapse-extra {
    svg {
      fill: #b5b5b5;
    }
  }

  .ant-collapse-content-box {
    padding: 10px 60px 40px 35px !important;

    @media (max-width: 750px) {
      padding: 10px 0px 20px 25px !important;
    }

    p {
      font-size: 18px;
      color: #45555c;
      line-height: 32px;
      margin: 0;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
`;

export const Wallpaper = styled.section`
  background-color: #4d4d4d;
  width: 100%;
  padding: 85px 20px 100px 20px;

  @media (max-width: 750px) {
    padding: 80px 20px;
  }

  h2 {
    padding-bottom: 20px;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: auto;
    width: fit-content;

    @media (max-width: 560px) {
      border-bottom: 8px solid #fff;
    }

    :after {
      content: '';
      width: 80%;
      height: 8px;
      background-color: #fff;
      position: absolute;
      bottom: -8px;

      @media (max-width: 560px) {
        display: none;
      }
    }
  }

  > div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 70px;

    @media (max-width: 900px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    .pc {
      width: 40%;
      height: auto;
      margin-right: 45px;

      @media (max-width: 1435px) {
        width: 50%;
      }

      @media (max-width: 1035px) {
        width: 60%;
      }

      @media (max-width: 900px) {
        margin-right: 0px;
        margin-top: 70px;
      }

      @media (max-width: 535px) {
        width: 100%;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: auto;
      }
    }

    .cel {
      width: 25%;
      height: auto;

      @media (max-width: 1435px) {
        width: 35%;
      }

      @media (max-width: 1035px) {
        width: 45%;
      }

      @media (max-width: 900px) {
        width: 60%;
      }

      @media (max-width: 535px) {
        width: 100%;
      }

      img {
        object-fit: contain;
        width: 40%;
        height: auto;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      a {
        margin-top: 60px;
        font-size: 28px;
        line-height: 39px;
        color: #fff;
        font-weight: bold;
        text-decoration: none;
        padding: 20px 50px;
        border-radius: 40px;
        background-color: #2980b9;
        transition: all ease 0.5s;

        :hover {
          background-color: #fff;
          color: #2980b9;
        }

        @media (max-width: 750px) {
          font-size: 22px;
          line-height: 33px;
          margin-top: 40px;
        }

        @media (max-width: 335px) {
          font-size: 20px;
          line-height: 31px;
        }
      }
    }
  }
`;

import styled, { keyframes } from 'styled-components';

import contato_bg from '../../assets/img/estrutura1.jpg';

const heartBeat = keyframes`
    0%, 100%, 40%, 60% {
        transform: scale(1);
    }
    20%, 80% {
        transform: scale(1.3);
    }
`;

export const Contato = styled.div`
  width: 100%;
  padding-top: 150px;
  margin-top: -150px;

  @media (max-width: 1370px) {
    padding-top: 182px;
    margin-top: -182px;
  }

  @media (max-width: 930px) {
    padding-top: 122px;
    margin-top: -122px;
  }

  @media (max-width: 580px) {
    padding-top: 117px;
    margin-top: -117px;
  }

  @media (max-width: 405px) {
    padding-top: 125px;
    margin-top: -125px;
  }

  > div {
    width: 100%;
    background-color: #4d4d4d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 90px 20px 60px 20px;

    @media (max-width: 750px) {
      padding: 60px 20px 40px 20px;
    }
  }

  h2 {
    font-size: 60px;
    line-height: 51px;
    color: #fff;
    margin-bottom: 40px;
    padding: 0px 120px 30px 120px;
    border-bottom: 8px solid #ffffff;
    width: fit-content;

    @media (max-width: 750px) {
      font-size: 45px;
      line-height: 36px;
      padding: 0 60px 25px 60px;
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 24px;
    line-height: 34px;
    color: #fff;
    font-weight: bold;
    max-width: 545px;
    text-align: center;
    margin-bottom: 40px;

    @media (max-width: 750px) {
      font-size: 20px;
      line-height: 30px;
      max-width: 450px;
      margin-bottom: 30px;
    }
  }

  .social {
    display: flex;

    a {
      margin: 0 15px;

      img {
        width: 40px;
        height: auto;
        transition: all ease 0.5s;

        @media (max-width: 750px) {
          width: 30px;
        }
      }

      :hover {
        img {
          transform: scale(1.2);
        }
      }
    }
  }

  .formulario {
    margin-top: 75px;
    width: 72.5%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 140px;

    @media (max-width: 1440px) {
      width: 80%;
    }

    @media (max-width: 1100px) {
      width: 100%;
    }

    @media (max-width: 750px) {
      flex-direction: column;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .imagem {
      width: 43%;
      height: 90%;
      position: absolute;
      left: 0;
      background-image: url(${contato_bg});
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 20px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 750px) {
        position: relative;
        height: fit-content;
        width: fit-content;
        justify-content: center;
        background-image: none;
      }

      @media (max-width: 570px) {
        width: 90%;
      }

      ::after {
        content: '';
        width: 45px;
        height: 77%;
        background-color: #2980b9;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        position: absolute;
        right: -45px;

        @media (max-width: 750px) {
          display: none;
        }
      }

      > div {
        align-self: flex-end;
        background-color: #2980b9;
        padding: 40px 35px;

        @media (max-width: 570px) {
          padding: 20px 15px;
        }

        h4 {
          font-size: 24px;
          line-height: 24px;
          color: #fff;
          margin-bottom: 15px;
        }

        a {
          font-size: 16px;
          line-height: 24px;
          color: #fff;
          text-decoration: none;
          max-width: 250px;
          display: flex;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: bold;
          color: #0e5072;
          padding: 6px 12px;
          background-color: #fff;
          margin: 0;
        }
      }
    }

    .form {
      width: 65%;
      background-color: #fff;
      border-radius: 20px;
      padding: 100px 85px 100px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: 1440px) {
        padding: 100px 55px 100px 0;
      }

      @media (max-width: 750px) {
        width: 100%;
        justify-content: center;
        padding: 80px 30px 60px 30px;
        position: relative;
        top: -50px;
      }

      @media (max-width: 400px) {
        padding: 80px 15px 60px 15px;
      }

      form {
        display: flex;
        flex-direction: column;
        width: 70%;

        @media (max-width: 750px) {
          width: 100%;
        }

        input,
        textarea {
          height: 50px;
          border-radius: 40px;
          width: 100%;
          background-color: #f8f8f8;
          border: none;
          margin: 12.5px 0;
          padding: 0 25px;
          font-size: 18px;
          color: #808080;
          font-weight: 300;
          line-height: 32px;
          font-family: 'Cera Pro';

          @media (max-width: 750px) {
            font-size: 16px;
            line-height: 30px;
          }
        }

        span {
          color: red;
        }

        textarea {
          height: 200px;
          padding: 15px 25px;
          border-radius: 20px;
          resize: none;

          @media (max-width: 750px) {
            height: 150px;
          }
        }

        .submitbtn {
          width: fit-content;
          position: relative;
          margin-top: 7.5px;
        }

        button {
          width: fit-content;
          padding: 20px 60px;
          border-radius: 40px;
          background-color: #2980b9;
          border: none;
          color: #fff;
          font-size: 24px;
          font-weight: 500;
          cursor: pointer;
          transition: all ease 0.5s;

          @media (max-width: 750px) {
            font-size: 20px;
            padding: 20px 40px;
          }

          :hover {
            background-color: #0bb4ff;
          }
        }
      }
    }
  }

  footer {
    border-top: 1px solid #797979;
    width: 72.5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;

    @media (max-width: 1440px) {
      width: 80%;
    }

    @media (max-width: 1100px) {
      width: 100%;
    }

    @media (max-width: 750px) {
      padding-top: 30px;
    }

    @media (max-width: 630px) {
      flex-direction: column;
    }

    p,
    a {
      font-size: 14px;
      color: #fff;
      text-decoration: none;
      margin: 0;
    }

    img {
      margin: 0 8px;
      height: 15px;
      position: relative;
      top: -5px;

      animation-name: ${heartBeat};
      animation-iteration-count: infinite;
      animation-duration: 2s;
    }
  }
`;

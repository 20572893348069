import styled from 'styled-components';

export const Navbar = styled.header`
  position: fixed;
  width: 100%;
  box-shadow: 0 13px 16px rgba(0, 0, 0, 0.02);
  z-index: 99;

  .topheader {
    background-color: #2980b9;
    padding: 9.5px 20px;
    width: 100%;

    @media (max-width: 405px) {
      padding: 5px 20px;
    }

    > div {
      max-width: 72.5%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 1580px) {
        max-width: 100%;
      }

      @media (max-width: 580px) {
        justify-content: flex-end;
      }

      .social {
        @media (max-width: 580px) {
          display: none;
        }

        a {
          margin: 0 7.5px;

          img {
            transition: all ease 0.5s;
          }

          :hover {
            img {
              transform: scale(1.2);
            }
          }
        }
      }

      .info {
        display: flex;
        align-items: center;

        @media (max-width: 405px) {
          flex-direction: column;
          align-items: flex-end;
        }

        a {
          font-size: 16px;
          color: #fff;
          line-height: 21px;
          text-decoration: none;
          display: flex;
          align-items: center;
          transition: all ease 0.5s;

          @media (max-width: 470px) {
            font-size: 14px;
          }

          :hover {
            color: #0bb4ff;

            p {
              color: #0bb4ff;
            }
          }

          svg {
            width: 20px;
          }
        }

        .agende {
          background-color: #0bb4ff;
          padding: 10px 25px;
          border-radius: 20px;
          margin-right: 55px;

          @media (max-width: 930px) {
            display: none;
          }

          :hover {
            background-color: #fff;
            color: #45555c;

            svg {
              fill: #45555c;
            }
          }

          svg {
            margin-right: 10px;
            fill: #fff;
            transition: all ease 0.5s;
            transform: scale(1.5);
          }
        }

        .tel {
          padding-right: 20px;
          border-right: 1px solid #fff;

          @media (max-width: 470px) {
            padding-right: 15px;
          }

          @media (max-width: 405px) {
            padding-right: 0;
            border-right: 0;
          }

          p {
            font-size: 16px;
            color: #fff;
            line-height: 21px;
            margin: 0;
            width: 80px;
            transition: all ease 0.5s;

            @media (max-width: 470px) {
              font-size: 14px;
              width: 70px;
            }
          }

          span {
            font-size: 12px;
            margin-right: 5px;
            position: relative;
            top: 1.4px;

            @media (max-width: 470px) {
              font-size: 10px;
            }
          }

          svg {
            transform: scale(1.3);
            margin-left: 15px;

            @media (max-width: 470px) {
              transform: scale(1.1);
              margin-left: 5px;
            }
          }
        }

        .endereco {
          margin-left: 20px;

          @media (max-width: 470px) {
            margin-left: 15px;
          }
        }

        .mapa {
          margin-left: 35px;
          font-weight: bold;
          position: relative;

          @media (max-width: 700px) {
            display: none;
          }

          .maps {
            background-color: #fff;
            height: 60px;
            width: 60px;
            position: absolute;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #d9d9d9;
          }

          > div {
            width: 60px;
            margin-left: 25px;
          }
        }
      }
    }
  }

  .navbar {
    background-color: #fff;
    padding: 15px 20px;
    width: 100%;
    position: relative;

    > div {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;

      @media (max-width: 1580px) {
        justify-content: space-between;
        width: 100%;
      }

      .logo {
        margin-right: 65px;

        @media (max-width: 1580px) {
          margin-right: 0px;
        }

        img {
          @media (max-width: 990px) {
            width: 200px;
            height: auto;
          }

          @media (max-width: 405px) {
            width: 180px;
          }
        }
      }

      > div {
        display: flex;

        @media (max-width: 1370px) {
          flex-direction: column;
        }

        @media (max-width: 930px) {
          position: absolute;
          top: ${(props) => (props.isOpen ? '100%' : '-500px')};
          background-color: #fff;
          width: 100%;
          left: 0;
          padding: 0px 20px 15px 20px;
          box-shadow: 0 13px 16px rgba(0, 0, 0, 0.02);
        }
      }

      nav {
        padding: 20px 15px;
        border-right: 1px solid #d9d9d9;
        margin-right: 30px;

        @media (max-width: 1370px) {
          border-right: none;
          margin-right: 0px;
          padding: 15px 0px 15px 15px;
        }

        @media (max-width: 930px) {
          padding: 0;
          padding-bottom: 15px;
        }

        @media (max-width: 510px) {
          padding: 0;
        }

        ul {
          display: flex;
          list-style: none;
          margin: 0;

          @media (max-width: 930px) {
            justify-content: center;
          }

          @media (max-width: 700px) {
            flex-direction: column;
            align-items: center;
          }

          li {
            margin: 0 15px;

            @media (max-width: 700px) {
              margin: 0;
              padding: 8px 0;
            }

            a {
              font-size: 16px;
              line-height: 21px;
              text-decoration: none;
              font-weight: bold;
              color: #b5b5b5;
              transition: all ease 0.5s;

              :hover {
                color: #2980b9;
              }
            }
          }
        }
      }

      .agendar {
        display: flex;
        align-items: center;

        @media (max-width: 1370px) {
          justify-content: flex-end;
        }

        @media (max-width: 930px) {
          justify-content: center;
        }

        @media (max-width: 360px) {
          flex-direction: column;
        }

        p {
          font-size: 16px;
          line-height: 21px;
          font-weight: bold;
          color: #b5b5b5;
          margin-right: 15px;
          margin-bottom: 0;

          @media (max-width: 360px) {
            margin-right: 0px;
            margin-bottom: 10px;
          }
        }

        .links {
          display: flex;
        }

        .telemedicina {
          display: none;

          @media (max-width: 930px) {
            display: inline-block;
          }
        }

        a {
          background-color: #2980b9;
          font-size: 16px;
          line-height: 21px;
          font-weight: bold;
          color: #fff;
          text-decoration: none;
          padding: 10px 15px;
          border-radius: 20px;
          margin: 0 5px;
          transition: all ease 0.5s;
          display: flex;
          align-items: center;

          :hover {
            background-color: #0bb4ff;
          }
        }

        .whats {
          padding: 0;
          background-color: unset;
          border-radius: 0;

          :hover {
            background-color: unset;
          }
        }
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Relacionados } from './style';
import ReactHtmlParser from 'react-html-parser';

import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

import api from '../../../services/api';

export default function Single({ match }) {
  const slug = match.params.slug;
  const [post, setPost] = useState();
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get(`/wp/v2/posts?_embed&slug=${slug}`).then((response) => {
      setPost(response.data[0]);
      setLoading(false);
    });

    api.get('/wp/v2/posts?per_page=3&_embed').then((response) => {
      const filtro = response.data.filter((post) => post.slug !== slug);
      setPosts(filtro);
    });
  }, [slug]);

  return (
    <>
      <Helmet>
        <title>
          {!!post
            ? `${!!post && post.title.rendered} - Vaskupien | Clínica Vascular`
            : 'Vaskupien | Clínica Vascular'}
        </title>
        <meta
          name="description"
          content="A evolução da medicina torna obrigatória a evolução de quem se dedica à ela. Assim, com dedicação e refinamento de nossas técnicas, hoje, agredimos menos e tratamos melhor."
        />
        <meta
          name="keywords"
          content="medicina, clínica, vascular, tratamento, cirurgia"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>
      <Header />
      <div className={`single-loader ${loading ? 'loading' : ''}`}>
        <div className="loader"></div>
      </div>
      <Container className={loading ? 'dontdisplay' : ''}>
        <div className="img">
          <img
            src={!!post && post._embedded['wp:featuredmedia'][0].source_url}
            alt=""
          />
        </div>
        <div className="title">
          <h2>{!!post && post.title.rendered}</h2>
        </div>
        <div className="content">
          {ReactHtmlParser(!!post && post.content.rendered)}
        </div>
      </Container>
      <Relacionados className={loading ? 'dontdisplay' : ''}>
        <h2>Relacionados</h2>
        <div>
          {!!posts &&
            posts.map((post) => (
              <a href={`/blog/${post.slug}`} key={Math.random()}>
                <div className="imagem">
                  <img
                    src={post._embedded['wp:featuredmedia'][0].source_url}
                    alt=""
                  />
                </div>
                <div className="texto">
                  <p>{post.title.rendered}</p>
                </div>
                <button>Saiba mais</button>
              </a>
            ))}
        </div>
      </Relacionados>
      <Footer />
    </>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  padding: 0 20px;
  margin: auto;
  border-bottom: 1px solid #45555c;

  @media (max-width: 1440px) {
    width: 80%;
  }

  @media (max-width: 750px) {
    width: 100%;
  }

  .img {
    margin-top: 50px;
    margin-bottom: 60px;
    width: 100%;
    height: 400px;

    @media (max-width: 750px) {
      height: 300px;
      margin-bottom: 40px;
      margin-top: 30px;
    }

    @media (max-width: 500px) {
      height: 250px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .title {
    width: 100%;

    h2 {
      color: #0e5072;
      text-align: start;
      margin-bottom: 50px;

      @media (max-width: 750px) {
        margin-bottom: 30px;
      }
    }
  }

  .content {
    margin-bottom: 80px;
    width: 100%;

    @media (max-width: 750px) {
      margin-bottom: 50px;
    }

    p {
      font-size: 20px;
      line-height: 40px;
      color: #45555c;
      font-weight: 300;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 32px;
      }
    }
  }
`;

export const Relacionados = styled.section`
  margin: auto;
  width: 72%;
  margin-top: 100px;
  margin-bottom: 200px;
  padding: 0 20px;

  @media (max-width: 1440px) {
    width: 90%;
  }

  @media (max-width: 750px) {
    width: 100%;
    margin-top: 60px;
  }

  h2 {
    border-bottom: 8px solid #2980b9;
    padding: 0px 70px 5px 0px;
    position: relative;
    margin-bottom: 120px;
    align-self: flex-start;
    z-index: 1;
    color: #0e5072;
    margin-left: 50px;
    margin-top: 100px;

    @media (max-width: 1000px) {
      margin-bottom: 60px;
    }

    @media (max-width: 750px) {
      padding: 0px 40px 5px 0px;
      margin-top: 50px;
      margin-left: 0px;
    }
  }

  > div {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    grid-gap: 65px;
    width: 100%;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 46%);
    }

    @media (max-width: 750px) {
      grid-gap: 40px;
    }

    @media (max-width: 550px) {
      grid-template-columns: 100%;
      grid-gap: 50px;
    }

    > a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      :hover {
        button {
          background-color: #2980b9;
        }
      }

      .imagem {
        width: 100%;
        height: 280px;
        border-radius: 16px;

        @media (max-width: 400px) {
          height: 220px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 16px;
        }
      }

      .texto {
        display: flex;
        margin-top: 35px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 10px;
        margin-bottom: 50px;

        @media (max-width: 750px) {
          margin-bottom: 30px;
        }

        p {
          margin: 0;
          color: #0e5072;
          font-size: 22px;
          line-height: 24px;
          /* max-width: 250px; */
          font-weight: bold;

          @media (max-width: 750px) {
            font-size: 18px;
            line-height: 20px;
          }
        }
      }

      button {
        margin-top: 0;
        font-weight: 500;
        font-size: 18px;
        color: #fff;
        padding: 10px 25px;
        background-color: #0bb4ff;
        border: none;
        transition: all ease 0.5s;
        cursor: pointer;
      }
    }
  }
`;

import { createGlobalStyle } from 'styled-components';
import 'antd/es/carousel/style/css';
import 'antd/es/collapse/style/index.css';
import 'antd/es/pagination/style/index.css';
import 'antd/es/notification/style/css';

import '@fancyapps/fancybox/src/css/core.css';

export default createGlobalStyle`

    @font-face {
        font-family: 'Cera Pro';
        src: url(${require('../assets/fonts/CeraPro-Bold.eot')});
        src: url(${require('../assets/fonts/CeraPro-Bold.eot?#iefix')}) format('embedded-opentype'),
            url(${require('../assets/fonts/CeraPro-Bold.woff2')}) format('woff2'),
            url(${require('../assets/fonts/CeraPro-Bold.woff')}) format('woff'),
            url(${require('../assets/fonts/CeraPro-Bold.ttf')}) format('truetype');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Cera Pro';
        src: url(${require('../assets/fonts/CeraPro-Italic.eot')});
        src: url(${require('../assets/fonts/CeraPro-Italic.eot?#iefix')}) format('embedded-opentype'),
            url(${require('../assets/fonts/CeraPro-Italic.woff2')}) format('woff2'),
            url(${require('../assets/fonts/CeraPro-Italic.woff')}) format('woff'),
            url(${require('../assets/fonts/CeraPro-Italic.ttf')}) format('truetype');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Cera Pro';
        src: url(${require('../assets/fonts/CeraPro-Medium.eot')});
        src: url(${require('../assets/fonts/CeraPro-Medium.eot?#iefix')}) format('embedded-opentype'),
            url(${require('../assets/fonts/CeraPro-Medium.woff2')}) format('woff2'),
            url(${require('../assets/fonts/CeraPro-Medium.woff')}) format('woff'),
            url(${require('../assets/fonts/CeraPro-Medium.ttf')}) format('truetype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Cera Pro';
        src: url(${require('../assets/fonts/CeraPro-Light.eot')});
        src: url(${require('../assets/fonts/CeraPro-Light.eot?#iefix')}) format('embedded-opentype'),
            url(${require('../assets/fonts/CeraPro-Light.woff2')}) format('woff2'),
            url(${require('../assets/fonts/CeraPro-Light.woff')}) format('woff'),
            url(${require('../assets/fonts/CeraPro-Light.ttf')}) format('truetype');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Cera Pro';
        src: url(${require('../assets/fonts/CeraPro-Black.eot')});
        src: url(${require('../assets/fonts/CeraPro-Black.eot?#iefix')}) format('embedded-opentype'),
            url(${require('../assets/fonts/CeraPro-Black.woff2')}) format('woff2'),
            url(${require('../assets/fonts/CeraPro-Black.woff')}) format('woff'),
            url(${require('../assets/fonts/CeraPro-Black.ttf')}) format('truetype');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }



    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    html,
    body,
    #root {
        /* height: 100%; */
        overflow-x: hidden;
        scroll-behavior: smooth;
    }

    body {
        background: #fff;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-family: 'Cera Pro';
    }

    h2 {
        font-size: 60px;
        line-height: 60px;
        font-weight: bold;
        color: #fff;
        border-bottom: 8px solid #ffffff;
        width: fit-content;
        text-align: center;

        @media (max-width: 750px) {
            font-size: 40px;
            line-height: 35px;
        }
    }

    .disabled {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        display: inline-block !important;
    }

    .single-loader {
        margin-top: 132px;

        @media (max-width: 1370px) {
            margin-top: 163px;
        }

        @media (max-width: 930px) {
            margin-top: 113.48px;
        }

        @media (max-width: 405px) {
            margin-top: 121.14px;
        }
    }

    .loading {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;

        .loader {
            border: 10px solid #f3f3f3; /* Light grey */
            border-top: 10px solid #2980b9; /* Blue */
            border-radius: 50%;
            width: 80px;
            height: 80px;
            animation: spin 2s linear infinite;
        }

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    }

    .dontdisplay {
        display: none;
    }




  /*!
    * Hamburgers
    * @description Tasty CSS-animated hamburgers
    * @author Jonathan Suh @jonsuh
    * @site https://jonsuh.com/hamburgers
    * @link https://github.com/jonsuh/hamburgers
    */
    .hamburger {
        padding: 0;
        display: none;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible; 

        @media (max-width: 930px) {
            display: inline-block;
        }
    }

    .hamburger:hover {
        opacity: 0.7; 
    }

    .hamburger.is-active:hover {
        opacity: 0.7; 
    }

    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner::before,
    .hamburger.is-active .hamburger-inner::after {
        background-color: #2980B9; 
    }

    .hamburger-box {
        width: 40px;
        height: 24px;
        display: inline-block;
        position: relative; 
    }

    .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: -2px; 
    }
        
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        width: 40px;
        height: 4px;
        background-color: #2980B9;
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease; 
    }

    .hamburger-inner::before, .hamburger-inner::after {
        content: "";
        display: block; 
    }

    .hamburger-inner::before {
        top: -10px; 
    }

    .hamburger-inner::after {
        bottom: -10px; 
    }

    
    /*
    * Collapse
    */
    .hamburger--collapse .hamburger-inner {
        top: auto;
        bottom: 0;
        transition-duration: 0.13s;
        transition-delay: 0.13s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); 
    }

    .hamburger--collapse .hamburger-inner::after {
        top: -20px;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    }

    .hamburger--collapse .hamburger-inner::before {
        transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); 
    }

    .hamburger--collapse.is-active .hamburger-inner {
        transform: translate3d(0, -10px, 0) rotate(-45deg);
        transition-delay: 0.22s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); 
    }

    .hamburger--collapse.is-active .hamburger-inner::after {
        top: 0;
        opacity: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; 
    }

    .hamburger--collapse.is-active .hamburger-inner::before {
        top: 0;
        transform: rotate(-90deg);
        transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); 
    }
    `;

import React, { useState, useRef, useEffect } from 'react';
import { Secao } from './style';
import { Carousel, notification } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import api from '../../services/api';
import ademail from '../../services/ademail';
import { email_geral } from '../../services/email';

export default function Depoimentos() {
  const [depoimentos, setDepoimentos] = useState();
  const [nota, setNota] = useState(0);
  const [autorizado, setAutorizado] = useState();
  const [disabledButton, setDisabledButton] = useState(false);
  const bannerDepoimentos = useRef();
  const bannerDepoimentosSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 1005,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('*Campo nome é obrigatório'),
      mensagem: Yup.string().required('*Campo mensagem é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('*Campo e-mail é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
                    <p>Nome: ${values.nome}</p>
                    <p>E-mail: ${values.email}</p>
                    <p>Mensagem: ${values.mensagem}</p>
                    <p>Nota: ${nota}</p>
                    <p>Autorizo publicar: ${autorizado}</p>
                  `;

      const mail = {
        to: email_geral, // sac@vaskupien.com.br
        from: values.email,
        as: `SAC - Clínica Vaskupien`,
        // bcc: JSON.stringify(['web@agenciaade.com.br']),
        subject: `Nova avaliação via site - Vaskupien`,
        message: body,
      };

      try {
        // Sucesso ao enviar
        setDisabledButton(true);
        await ademail.post('/email', mail, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        notification.success({
          message: 'Avaliação enviada com sucesso! 🚀',
          placement: 'bottomRight',
        });

        resetForm();
        setDisabledButton(true);
        setNota(0);
        setAutorizado();
      } catch (error) {
        // Erro ao enviar
        notification.error({
          message: 'Não foi possivel enviar! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  useEffect(() => {
    api.get('/wp/v2/pages?slug=home&fields=acf.depoimentos').then((response) => {
      setDepoimentos(response.data[0].acf.depoimentos);
    });
  }, []);

  return (
    <Secao id="depoimentos">
      <div id="bg" />
      <div className="content">
        <div className="title">
          <h2>Depoimentos</h2>
        </div>
        {/* <p className="filtre">Filtre por avaliações:</p>
        <div className="filtros">
          <button
            onClick={() => setDepoimentos('8')}
            className={depoimentos === '8' ? 'active' : ''}
          >
            10 - 8
          </button>
          <button
            onClick={() => setDepoimentos('5')}
            className={depoimentos === '5' ? 'active' : ''}
          >
            7 - 5
          </button>
          <button
            onClick={() => setDepoimentos('1')}
            className={depoimentos === '1' ? 'active' : ''}
          >
            4 - 1
          </button>
        </div> */}
        <div className="carousel">
          <Carousel ref={bannerDepoimentos} {...bannerDepoimentosSettings}>
            {!!depoimentos &&
              depoimentos.map((depo) => (
                <div className="slider" key={Math.random()}>
                  <p className="aspas">"</p>
                  <p>{depo.texto}</p>
                  <div>
                    <p className="nome">{depo.nome}</p>
                    <p className="nota">{depo.nota}</p>
                  </div>
                </div>
              ))}
          </Carousel>
        </div>
        <div className="avaliacao">
          <h2>Deixe sua avaliação</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="inputs">
              <div>
                <input
                  type="text"
                  name="nome"
                  placeholder="Nome"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nome}
                />
                {formik.touched.nome && formik.errors.nome ? (
                  <span>{formik.errors.nome}</span>
                ) : null}
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <span>{formik.errors.email}</span>
                ) : null}
              </div>
            </div>
            <textarea
              name="mensagem"
              placeholder="Mensagem"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mensagem}
            />
            {formik.touched.mensagem && formik.errors.mensagem ? (
              <span>{formik.errors.mensagem}</span>
            ) : null}
            <p>Selecione o quanto você indicaria para um amigo?</p>
            <div className="notas">
              <div>
                <button
                  type="button"
                  onClick={() => setNota(1)}
                  className={nota === 1 ? 'active' : ''}
                >
                  1
                </button>
                <button
                  type="button"
                  onClick={() => setNota(2)}
                  className={nota === 2 ? 'active' : ''}
                >
                  2
                </button>
                <button
                  type="button"
                  onClick={() => setNota(3)}
                  className={nota === 3 ? 'active' : ''}
                >
                  3
                </button>
                <button
                  type="button"
                  onClick={() => setNota(4)}
                  className={nota === 4 ? 'active' : ''}
                >
                  4
                </button>
                <button
                  type="button"
                  onClick={() => setNota(5)}
                  className={nota === 5 ? 'active' : ''}
                >
                  5
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => setNota(6)}
                  className={nota === 6 ? 'active' : ''}
                >
                  6
                </button>
                <button
                  type="button"
                  onClick={() => setNota(7)}
                  className={nota === 7 ? 'active' : ''}
                >
                  7
                </button>
                <button
                  type="button"
                  onClick={() => setNota(8)}
                  className={nota === 8 ? 'active' : ''}
                >
                  8
                </button>
                <button
                  type="button"
                  onClick={() => setNota(9)}
                  className={nota === 9 ? 'active' : ''}
                >
                  9
                </button>
                <button
                  type="button"
                  onClick={() => setNota(10)}
                  className={nota === 10 ? 'active' : ''}
                >
                  10
                </button>
              </div>
            </div>
            <div className="autorizacao">
              <p>Autorizo a publicação dessa avaliação</p>
              <div>
                <div>
                  <button
                    id="sim-btn"
                    type="button"
                    onClick={() => setAutorizado('Sim')}
                  >
                    <div className={autorizado === 'Sim' ? 'check' : ''} />
                  </button>
                  <label htmlFor="sim-btn">SIM</label>
                </div>
                <div>
                  <button
                    id="nao-btn"
                    type="button"
                    onClick={() => setAutorizado('Não')}
                  >
                    <div className={autorizado === 'Não' ? 'check' : ''} />
                  </button>
                  <label htmlFor="nao-btn">NÃO</label>
                </div>
              </div>
            </div>
            <div className="send-btn">
              <button className="enviar" type="submit">
                Enviar
              </button>
              <div className={disabledButton ? 'disabled' : ''} />
            </div>
          </form>
        </div>
      </div>
    </Secao>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Carousel, Collapse, notification } from 'antd';
import { useFormik } from 'formik';
import Input from 'react-input-mask';
import * as Yup from 'yup';
import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowBack,
} from 'react-icons/io';
import Lightbox, { Modal, ModalGateway } from 'react-images';
import ReactHtmlParser from 'react-html-parser';

// api
// api
import ademail from '../../services/ademail';
import api from '../../services/api';
import axios from 'axios';

// components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Depoimentos from '../../components/Depoimentos/Depoimentos';

import {
  Container,
  Banner,
  Agende,
  Sobre,
  Formacao,
  Doencas,
  Especialidades,
  Certificados,
  Servicos,
  Estrutura,
  Convenios,
  Blog,
  Perguntas,
  Wallpaper,
} from './styles';
import { email_geral } from '../../services/email';

export default function Home() {
  // banner
  const [banners, setBanners] = useState();
  const banner = useRef();
  const bannerSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoPlaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    pauseOnHover: true,
  };

  useEffect(() => {
    api.get('/acf/v3/options/options_banners').then((response) => {
      setBanners(response.data.acf);
    });
  }, []);

  // Agenda
  const carouselAgenda = useRef();
  const [agenda, setAgenda] = useState('Consulta');
  const [consultaDados, setConsultaDados] = useState();
  const [showModalAgenda, setShowModalAgenda] = useState(false);
  const [dia, setDia] = useState();
  const [hora, setHora] = useState();
  const [diaDaSemana, setDiaDaSemana] = useState();
  const [disabledAgendaButton, setDisabledAgendaButton] = useState(false);
  const [loadingAgenda, setLoadingAgenda] = useState(false);

  const carouselAgendaSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // agendamento
  const formikAgendaHorario = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('*Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('*Campo e-mail é obrigatório'),
      telefone: Yup.string().required('*Campo telefone é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
        <h2>${agenda} ${agenda === 'Consulta' ? 'marcada' : 'marcado'} para o dia:</h2>
        <p>${dia} ás ${hora}</p>
        <p>Nome: ${values.nome}</p>
        <p>E-mail: ${values.email}</p>
        <p>Telefone: ${values.telefone}</p>
      `;

      const formData = {
        name: values.nome,
        email: values.email,
        message: body,
      };

      const clientebody = `
        <p>${values.nome}, obrigado pela preferência! Em breve entraremos em contato através do telefone ${values.telefone} para confirmar ${agenda === 'Consulta' ? 'sua' : 'seu'
        } ${agenda} para o dia ${dia} ás ${hora}hrs.</p>
      `;

      // envio de email ao cliente e um bcc para o email geral
      const clientmail = {
        to: values.email,
        from: email_geral,
        bcc: email_geral,
        subject: `Agendamento de ${agenda} via site - Vaskupien`,
        as: `${agenda === 'Consulta' ? 'CONSULTA' : 'EXAME'} - Clínica Vaskupien`,
        message: clientebody,
      };

      const clientHeader = { // obrigatorio na nova api
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        // Sucesso ao enviar
        setDisabledAgendaButton(true);

        // await ademail.post('/email', mail, header);
        await ademail.post('/email', clientmail, clientHeader);

        notification.success({
          message: `Agradecemos pela preferência! Em breve entraremos em contato para confirmar ${agenda === 'Consulta' ? 'sua' : 'seu'
            } ${agenda}!`,
          placement: 'bottomRight',
        });

        resetForm();
        setDia();
        setHora();
        setShowModalAgenda(false);
        setDisabledAgendaButton(false);
      } catch (error) {
        // Erro ao enviar
        console.log(error);
        setDisabledAgendaButton(false);
        notification.error({
          message: 'Não foi possivel concluir seu pedido! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  const formikAgendaContato = useFormik({
    initialValues: {
      nome: '',
      telefone: '',
      email: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('*Campo nome é obrigatório'),
      telefone: Yup.string().required('*Campo telefone é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('*Campo e-mail é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
                    <h2>Busca de horário de ${agenda} via site - Vaskupien <br/></h2>
                    <p>Nome: ${values.nome}</p>
                    <p>Telefone: ${values.telefone}</p>
                    <p>E-mail: ${values.email}</p>
                  `;

      const header = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        // Sucesso ao enviar
        setDisabledAgendaButton(true);

        await ademail.post('/email',
          {
            to: email_geral,
            from: values.email,
            as: `CONTATO - Clínica Vaskupien`,
            subject: `Busca de horário de ${agenda} via site - Vaskupien`,
            message: body,
          }, header);

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });

        resetForm();
        setDisabledAgendaButton(false);
      } catch (error) {
        // Erro ao enviar
        setDisabledAgendaButton(false);
        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  useEffect(() => {
    setLoadingAgenda(true);
    if (agenda === 'Consulta') {
      api.get(`/wp/v2/agenda/consulta-local`).then((response) => {
        setConsultaDados(response.data.consulta_local);
        setLoadingAgenda(false);
      });
    } else if (agenda === 'Exame') {
      api.get(`/wp/v2/agenda/exame`).then((response) => {
        setConsultaDados(response.data.exame);
        setLoadingAgenda(false);
      });
    }
  }, [agenda]);

  function onChildChanged(e) {
    setAgenda(e);
  }

  // sobre
  const [sobre, setSobre] = useState();
  useEffect(() => {
    api.get('/wp/v2/pages?slug=home&_fields=acf.sobre').then((response) => {
      setSobre(response.data[0].acf.sobre);
    });
  }, []);

  // especialidades
  const [especialidades, setEspecialidades] = useState();
  useEffect(() => {
    api
      .get('/wp/v2/pages?slug=home&_fields=acf.especialidades')
      .then((response) => {
        setEspecialidades(response.data[0].acf.especialidades);
      });
  }, []);

  const [dedicamos, setDedicamos] = useState();
  useEffect(() => {
    api
      .get('/wp/v2/pages?slug=home&_fields=acf.nos_dedicamos_a')
      .then((response) => {
        setDedicamos(response.data[0].acf.nos_dedicamos_a);
      });
  }, []);

  // formacao
  const [formacao, setFormacao] = useState();
  useEffect(() => {
    api.get('/wp/v2/pages?slug=home&_fields=acf.formacao').then((response) => {
      setFormacao(response.data[0].acf.formacao);
    });
  }, []);

  // cuidamos de
  const [cuidamos, setCuidamos] = useState();
  useEffect(() => {
    api
      .get('/wp/v2/pages?slug=home&_fields=acf.cuidamos_de')
      .then((response) => {
        setCuidamos(response.data[0].acf.cuidamos_de);
      });
  }, []);

  // certificados
  const [modalCertificadosIsopen, setModalCertificadosIsOpen] = useState(false);
  const [selectedCertificadosIndex, setSelectedCertificadosIndex] = useState(0);
  const [certificados, setCertificados] = useState();

  useEffect(() => {
    api
      .get('/wp/v2/pages?slug=home&_fields=acf.certificados')
      .then((response) => {
        setCertificados(response.data[0].acf.certificados);
      });
  }, []);

  let indexCertificados = -1;
  const imagesCertificados =
    !!certificados &&
    certificados.map((foto) => ({
      index: ++indexCertificados,
      src: foto.sizes.large,
    }));

  // serviços
  const [servicos, setServicos] = useState();
  useEffect(() => {
    api.get('/wp/v2/pages?slug=home&_fields=acf.servicos').then((response) => {
      setServicos(response.data[0].acf.servicos);
    });
  }, []);

  // estrutura
  const [modalEstruturaIsopen, setModalEstruturaIsOpen] = useState(false);
  const [selectedEstruturaIndex, setSelectedEstruturaIndex] = useState(0);
  const [estrutura, setEstrutura] = useState();

  useEffect(() => {
    api.get('/wp/v2/pages?slug=home&_fields=acf.estrutura').then((response) => {
      setEstrutura(response.data[0].acf.estrutura);
    });
  }, []);

  let indexEstrutura = -1;
  const imagesEstrutura =
    !!estrutura &&
    estrutura.map((foto) => ({
      index: ++indexEstrutura,
      src: foto.sizes.large,
    }));

  // convenios
  const [convenios, setConvenios] = useState();

  useEffect(() => {
    api.get('/wp/v2/pages?slug=home&_fields=acf.convenios').then((response) => {
      setConvenios(response.data[0].acf.convenios);
    });
  }, []);

  // blog
  const [posts, setPosts] = useState();

  useEffect(() => {
    api.get(`/wp/v2/posts?per_page=3&_embed`).then((response) => {
      setPosts(response.data);
    });
  }, []);

  // perguntas
  const { Panel } = Collapse;
  const genExtra = () => <IoIosArrowDown />;
  const [perguntas, setPerguntas] = useState();
  useEffect(() => {
    api
      .get('/wp/v2/pages?slug=home&_fields=acf.perguntas_frequentes')
      .then((response) => {
        setPerguntas(response.data[0].acf.perguntas_frequentes);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Vaskupien | Clínica Vascular</title>
        <meta
          name="description"
          content="A evolução da medicina torna obrigatória a evolução de quem se dedica à ela. Assim, com dedicação e refinamento de nossas técnicas, hoje, agredimos menos e tratamos melhor."
        />
        <meta
          name="keywords"
          content="medicina, clínica, vascular, tratamento, cirurgia"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/screenshot.png'}
        />
      </Helmet>
      <Header callbackParent={(e) => onChildChanged(e)} />
      <Container>
        <Banner>
          <div className="btns">
            <button type="button" onClick={() => banner.current.prev()}>
              <IoIosArrowBack />
            </button>
            <button type="button" onClick={() => banner.current.next()}>
              <IoIosArrowForward />
            </button>
          </div>

          <Carousel ref={banner} {...bannerSettings}>
            {!!banners &&
              banners.banners.map((banner) => (
                <div className="slider">
                  <div className="imagem">
                    <img src={banner.imagem.sizes.large} alt="Banner 1" />
                  </div>
                  <div className="texto">
                    <h4>{banner.subtitulo}</h4>
                    <h2>{banner.titulo}</h2>
                  </div>
                </div>
              ))}
            <div className="slider video">
              <div className="video-container">
                <video controls autoPlay loop muted>
                  <source
                    src={require('../../assets/video/VaskupienSite.mp4')}
                    type="video/mp4"
                  />
                </video>
              </div>
              <div className="imagem">
                <img
                  src={!!banners && banners.video.imagem_video}
                  alt="Banner"
                />
                <div className="overlay" />
              </div>
              <div className="texto">
                <h4>{!!banners && banners.video.subtitulo_video}</h4>
                <h2>{!!banners && banners.video.titulo_video}</h2>
              </div>
            </div>
          </Carousel>
        </Banner>
        <Agende id="consulta">
          <div>
            <div className="consulta">
              <h3>AGENDE SUA CONSULTA</h3>
              <p>
                Próximos horários disponíveis. Selecione e marque sua consulta
              </p>
              <div className="agenda">
                <div className="buttons">
                  <button
                    className={`item ${agenda === 'Consulta' ? 'active' : ''}`}
                    onClick={() => {
                      setAgenda('Consulta');
                      setLoadingAgenda(true);
                    }}
                  >
                    CONSULTA LOCAL
                  </button>
                  <button
                    className={`item ${agenda === 'Exame' ? 'active' : ''}`}
                    onClick={() => {
                      setAgenda('Exame');
                      setLoadingAgenda(true);
                    }}
                  >
                    <p>EXAME</p>
                  </button>
                </div>
                <div
                  className={`horarios showHorariosConsulta
                  } ${showModalAgenda ? 'dontshow' : ''}`}
                >
                  <button
                    className="slider-btn"
                    onClick={() => carouselAgenda.current.prev()}
                  >
                    <IoIosArrowBack />
                  </button>

                  <div className={loadingAgenda ? 'loading' : ''}>
                    <div className="loader"></div>
                  </div>
                  <div
                    className={`carousel ${loadingAgenda ? 'dontdisplay' : ''}`}
                  >
                    <Carousel ref={carouselAgenda} {...carouselAgendaSettings}>
                      {!!consultaDados &&
                        consultaDados.map((dia, index) => {
                          const day = dia.data_formatada.substr(8, 9);
                          const month = dia.data_formatada.substring(5, 7);
                          let mes = '';

                          if (month === '01') {
                            mes = 'Janeiro';
                          }

                          if (month === '02') {
                            mes = 'Fevereiro';
                          }

                          if (month === '03') {
                            mes = 'Março';
                          }

                          if (month === '04') {
                            mes = 'Abril';
                          }

                          if (month === '05') {
                            mes = 'Maio';
                          }

                          if (month === '06') {
                            mes = 'Junho';
                          }

                          if (month === '07') {
                            mes = 'Julho';
                          }

                          if (month === '08') {
                            mes = 'Agosto';
                          }

                          if (month === '09') {
                            mes = 'Setembro';
                          }

                          if (month === '10') {
                            mes = 'Outubro';
                          }

                          if (month === '11') {
                            mes = 'Novembro';
                          }

                          if (month === '12') {
                            mes = 'Dezembro';
                          }

                          return (
                            <div key={dia.index}>
                              <div className="title">
                                <h4>
                                  {day} DE {mes.toUpperCase()}
                                </h4>
                                <p>{dia.dia_da_semana}</p>
                              </div>
                              <div className="horario">
                                {dia.horarios === false ? (
                                  <img
                                    width="9px"
                                    height="3px"
                                    src={require('../../assets/img/traço.jpg')}
                                    alt=""
                                  />
                                ) : (
                                  ''
                                )}
                                {!!dia.horarios &&
                                  dia.horarios.map((hora) => (
                                    <button
                                      key={Math.random()}
                                      onClick={() => {
                                        setShowModalAgenda(true);
                                        setDia(
                                          `${day} de ${mes.toUpperCase()}`
                                        );
                                        setHora(hora.horario_de_entrada);
                                        setDiaDaSemana(dia.dia_da_semana);
                                      }}
                                    >
                                      {hora.horario_de_entrada}
                                    </button>
                                  ))}
                              </div>
                            </div>
                          );
                        })}
                    </Carousel>
                  </div>
                  <button
                    className="slider-btn next"
                    onClick={() => carouselAgenda.current.next()}
                  >
                    <IoIosArrowForward />
                  </button>
                </div>
              </div>
              <div className={`modal ${showModalAgenda ? 'show' : ''}`}>
                <h2>
                  {dia} às {hora}
                </h2>
                <p>{diaDaSemana}</p>
                <form onSubmit={formikAgendaHorario.handleSubmit}>
                  <div>
                    <input
                      type="text"
                      name="nome"
                      placeholder="Nome"
                      onChange={formikAgendaHorario.handleChange}
                      onBlur={formikAgendaHorario.handleBlur}
                      value={formikAgendaHorario.values.nome}
                    />
                    {formikAgendaHorario.touched.nome &&
                      formikAgendaHorario.errors.nome ? (
                      <span>{formikAgendaHorario.errors.nome}</span>
                    ) : null}

                    <input
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      onChange={formikAgendaHorario.handleChange}
                      onBlur={formikAgendaHorario.handleBlur}
                      value={formikAgendaHorario.values.email}
                    />
                    {formikAgendaHorario.touched.email &&
                      formikAgendaHorario.errors.email ? (
                      <span>{formikAgendaHorario.errors.email}</span>
                    ) : null}

                    <Input
                      type="tel"
                      name="telefone"
                      mask={
                        formikAgendaHorario.values.telefone.length <= 16
                          ? '(99) 9 9999-9999'
                          : '(99) 9 99999-9999'
                      }
                      formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                      maskChar={null}
                      placeholder="Telefone"
                      onChange={formikAgendaHorario.handleChange}
                      onBlur={formikAgendaHorario.handleBlur}
                      value={formikAgendaHorario.values.telefone}
                    />
                    {formikAgendaHorario.touched.telefone &&
                      formikAgendaHorario.errors.telefone ? (
                      <span>{formikAgendaHorario.errors.telefone}</span>
                    ) : null}
                  </div>
                  <div className="infos">
                    <h4>HORÁRIO MARCADO PARA:</h4>
                    <p>
                      Nome: <strong>{formikAgendaHorario.values.nome}</strong>
                    </p>
                    <p>
                      E-mail:{' '}
                      <strong>{formikAgendaHorario.values.email}</strong>
                    </p>
                    <p>
                      Telefone:{' '}
                      <strong>{formikAgendaHorario.values.telefone}</strong>
                    </p>
                    <p className="alerta">
                      O seu horário será valido após a confirmação com a nossa
                      equipe, por favor aguarde o nosso retorno!
                    </p>
                    <div>
                      <button
                        className="cancelar"
                        type="button"
                        onClick={() => setShowModalAgenda(false)}
                      >
                        Cancelar
                      </button>
                      <button className="confirmar" type="submit">
                        Confirmar
                      </button>
                      <div className={disabledAgendaButton ? 'disabled' : ''} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="contato">
              <p>
                <strong>Caso não encontre um horário adequado</strong> deixe seu
                contato, buscaremos um horário que possa se encaixar à sua
                necessidade.
              </p>
              <form onSubmit={formikAgendaContato.handleSubmit}>
                <div className="inputs">
                  <div>
                    <input
                      type="text"
                      name="nome"
                      placeholder="Nome"
                      onChange={formikAgendaContato.handleChange}
                      onBlur={formikAgendaContato.handleBlur}
                      value={formikAgendaContato.values.nome}
                    />
                    {formikAgendaContato.touched.nome &&
                      formikAgendaContato.errors.nome ? (
                      <span>{formikAgendaContato.errors.nome}</span>
                    ) : null}
                  </div>

                  <div>
                    <Input
                      type="tel"
                      name="telefone"
                      mask={
                        formikAgendaContato.values.telefone.length <= 16
                          ? '(99) 9 9999-9999'
                          : '(99) 9 9999-9999'
                      }
                      formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                      maskChar={null}
                      placeholder="Telefone"
                      onChange={formikAgendaContato.handleChange}
                      onBlur={formikAgendaContato.handleBlur}
                      value={formikAgendaContato.values.telefone}
                    />
                    {formikAgendaContato.touched.telefone &&
                      formikAgendaContato.errors.telefone ? (
                      <span>{formikAgendaContato.errors.telefone}</span>
                    ) : null}
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      onChange={formikAgendaContato.handleChange}
                      onBlur={formikAgendaContato.handleBlur}
                      value={formikAgendaContato.values.email}
                    />
                    {formikAgendaContato.touched.email &&
                      formikAgendaContato.errors.email ? (
                      <span>{formikAgendaContato.errors.email}</span>
                    ) : null}
                  </div>
                </div>
                <div className="submitbtn">
                  <button type="submit">Solicitar Contato</button>
                  <div className={disabledAgendaButton ? 'disabled' : ''} />
                </div>
              </form>
            </div>
          </div>
        </Agende>
        <Sobre id="sobre">
          <h2>
            <img
              src={require('../../assets/img/title-detail.svg')}
              alt="Detalhe"
            />
            Sobre
          </h2>
          <div>
            <div className="top-text">
              <div>
                <div className="blue-bg" />
                <div className="text">
                  <p>{!!sobre && sobre.texto}</p>
                  <div>
                    <img
                      src={require('../../assets/logo.svg')}
                      alt="Logo Vaskupien"
                    />
                  </div>
                </div>
              </div>
              <div className="imagem">
                <img src={require('../../assets/img/sobre.png')} alt="" />
              </div>
            </div>
            <div className="bottom-text">
              <div className="imagem">
                <img
                  src={require('../../assets/img/FELIPE_SKUPIENcopy.png')}
                  alt="Felipe Skupien"
                />
              </div>
              <div className="text">
                <p className="nome">Dr. Felipe Skupien</p>
                {ReactHtmlParser(!!sobre && sobre.sobre_o_dr)}
              </div>
            </div>
          </div>
        </Sobre>
        <Formacao>
          <h2>Formação</h2>
          <div>
            {!!formacao &&
              formacao.map((form) => (
                <div key={Math.random()}>
                  <p>
                    <strong>{form.titulo}</strong>,{form.descricao}
                  </p>
                </div>
              ))}
          </div>
        </Formacao>
        <Doencas>
          <div>
            <h2>
              <img
                src={require('../../assets/img/title-detail.svg')}
                alt="Detalhe"
              />
              Cuidamos de
            </h2>
            <div className="doencas">
              {!!cuidamos &&
                cuidamos.map((doenca) => (
                  <p key={Math.random()}>{doenca.titulo}</p>
                ))}
            </div>
          </div>
        </Doencas>
        <Especialidades id="especialidades">
          <div>
            <h2>
              <img
                src={require('../../assets/img/title-detail.svg')}
                alt="Detalhe"
              />
              Especialidades
            </h2>

            <div className="rqe">
              {!!especialidades &&
                especialidades.map((espe, index) => (
                  <div key={index}>
                    <p>{espe.titulo}</p>
                  </div>
                ))}
              <div>
                <img
                  src={require('../../assets/img/especialidades-bg.jpg')}
                  alt=""
                />
              </div>
            </div>
            <div className="especialidades">
              <p className="texto-divisao">Nos dedicamos a</p>
              <div className="cards">
                {!!dedicamos &&
                  dedicamos.map((espe, index) => (
                    <div key={index}>
                      <p>{espe.titulo}</p>
                    </div>
                  ))}
                <div>
                  <img
                    src={require('../../assets/img/especialidades-bg.jpg')}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="blue-detail" />
        </Especialidades>
        <Certificados>
          <div>
            <h2>
              <img
                src={require('../../assets/img/title-detail.svg')}
                alt="Detalhe"
              />
              Certificados
            </h2>
            <div className="certificados">
              {!!certificados &&
                certificados.map((foto, index) => (
                  <div
                    key={foto.ID}
                    className="imagem"
                    onClick={() => {
                      setModalCertificadosIsOpen(!modalCertificadosIsopen);
                      setSelectedCertificadosIndex(index);
                    }}
                  >
                    <img alt="" src={foto.sizes.thumbnail} />
                  </div>
                ))}
            </div>
          </div>
        </Certificados>
        <Servicos>
          <div>
            <h2>
              <img
                src={require('../../assets/img/title-detail.svg')}
                alt="Detalhe"
              />
              Serviços
            </h2>
            <div className="servicos">
              {!!servicos &&
                servicos.map((servico) => (
                  <p key={Math.random()}>{servico.titulo}</p>
                ))}
            </div>
          </div>
        </Servicos>
        <Estrutura id="estrutura">
          <img
            id="detail"
            src={require('../../assets/img/vasos.svg')}
            alt="Vasos Sanguíneos"
          />
          <h2>
            <img
              src={require('../../assets/img/title-detail.svg')}
              alt="Detalhe"
            />
            Estrutura
          </h2>
          <div>
            {!!estrutura &&
              estrutura.map((foto, index) => (
                <div
                  key={index}
                  className="imagem"
                  onClick={() => {
                    setModalEstruturaIsOpen(!modalEstruturaIsopen);
                    setSelectedEstruturaIndex(index);
                  }}
                >
                  <img alt="" src={foto.sizes.thumbnail} />
                </div>
              ))}
          </div>
        </Estrutura>
        <Convenios>
          <h2>
            <img
              src={require('../../assets/img/title-detail.svg')}
              alt="Detalhe"
            />
            Convênios
          </h2>
          <div>
            {!!convenios &&
              convenios.map((foto) => (
                <div key={foto.ID}>
                  <img src={foto.sizes.medium} alt="Unimed Logo" />
                </div>
              ))}
          </div>
          <p>Atendemos todos os convênios mediante o Reembolso</p>
        </Convenios>
        <Blog id="blog">
          <h2>
            <img
              src={require('../../assets/img/title-detail.svg')}
              alt="Detalhe"
            />
            Blog
          </h2>
          <div>
            {!!posts &&
              posts.map((post) => (
                <a href={`/blog/${post.slug}`} key={Math.random()}>
                  <div className="imagem">
                    <img
                      src={post._embedded['wp:featuredmedia'][0].source_url}
                      alt=""
                    />
                  </div>
                  <div className="texto">
                    <p>{post.title.rendered}</p>
                  </div>
                  <button>Saiba mais</button>
                </a>
              ))}
          </div>
          <a className="mais" href="/blog">
            Veja mais
          </a>
        </Blog>
        <Perguntas>
          <img
            id="detail"
            src={require('../../assets/img/vasos.svg')}
            alt="Vasos Sanguíneos"
          />
          <h2>
            <img
              src={require('../../assets/img/title-detail.svg')}
              alt="Detalhe"
            />
            Perguntas Frequentes
          </h2>
          <Collapse accordion ghost>
            {!!perguntas &&
              perguntas.map((pergunta, index) => (
                <Panel
                  header={pergunta.pergunta}
                  key={index}
                  showArrow={false}
                  extra={genExtra()}
                >
                  <p>{pergunta.resposta}</p>
                </Panel>
              ))}
          </Collapse>
        </Perguntas>
        <Depoimentos />
        <Wallpaper>
          <h2>Baixe nosso wallpaper</h2>
          <div>
            <div className="pc">
              <img
                src={require('../../assets/img/wallpaper-pc.png')}
                alt="Wallpaper Computador"
              />
              <a
                download="Wallpaper Vaskupien"
                target="_blank"
                rel="noopener noreferrer"
                href={require('../../assets/img/wallpaperpc.jpg')}
              >
                Baixar para PC
              </a>
            </div>
            <div className="cel">
              <img
                src={require('../../assets/img/wallpaper-cel.png')}
                alt="Wallpaper Celular"
              />
              <a
                download="Wallpaper Vaskupien"
                target="_blank"
                rel="noopener noreferrer"
                href={require('../../assets/img/wallpapercelular.jpg')}
              >
                Baixar para Celular
              </a>
            </div>
          </div>
        </Wallpaper>
      </Container>
      <Footer />
      {/* Gateway lightbox */}
      <ModalGateway>
        {modalCertificadosIsopen && (
          <Modal
            onClose={() => setModalCertificadosIsOpen(!modalCertificadosIsopen)}
          >
            <Lightbox
              views={imagesCertificados}
              currentIndex={selectedCertificadosIndex}
            />
          </Modal>
        )}
        {modalEstruturaIsopen && (
          <Modal onClose={() => setModalEstruturaIsOpen(!modalEstruturaIsopen)}>
            <Lightbox
              views={imagesEstrutura}
              currentIndex={selectedEstruturaIndex}
            />
          </Modal>
        )}
      </ModalGateway>
    </>
  );
}

import React, { useState } from 'react';
import Input from 'react-input-mask';
import { useFormik } from 'formik';
import { notification } from 'antd';
import * as Yup from 'yup';

import { Contato } from './style';

// api
import ademail from '../../services/ademail';
import { email_geral } from '../../services/email';

export default function Footer() {
  const [disabledButton, setDisabledButton] = useState(false);
  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('*Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('*Campo e-mail é obrigatório'),
      telefone: Yup.string().required('*Campo telefone é obrigatório'),
      mensagem: Yup.string().required('*Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
                    <p>Nome: ${values.nome}</p>
                    <p>E-mail: ${values.email}</p>
                    <p>Telefone: ${values.telefone}</p>
                    <p>Mensagem: ${values.mensagem}</p>
                  `;

      try {
        // Sucesso ao enviar
        setDisabledButton(true);
        await ademail.post(
          '/email',
          {
            to: email_geral,
            from: values.email,
            as: `CONTATO - Clínica Vaskupien`,
            subject: 'Novo contato via site - Vaskupien',
            message: body,
          }
          , {
            headers: {
              'Content-Type': 'application/json'
            }
          });

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });

        resetForm();
        setDisabledButton(false);
      } catch (error) {
        // Erro ao enviar
        console.log(error);
        setDisabledButton(false);
        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  return (
    <Contato id="contato">
      <div>
        <h2>Contato</h2>
        {/* <p>
          Entre em contato comigo na unidade que for mais prática para você!
        </p> */}
        {/* <div className="social">
          <a href="https://pt-br.facebook.com/vaskupienclinica/">
            <img
              src={require('../../assets/img/facebook.svg')}
              alt="Facebook Icon"
            />
          </a>
          <a href="/">
            <img
              src={require('../../assets/img/instagram.svg')}
              alt="Instagram Icon"
            />
          </a>
          <a href="/">
            <img
              src={require('../../assets/img/linkedin.svg')}
              alt="LinkedIn Icon"
            />
          </a>
        </div> */}
        <div className="formulario">
          <div className="imagem">
            <div>
              <h4>
                Vaskupien Clínica <br />
                Vascular
              </h4>
              <a
                className="endereco"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/maps/place/Vaskupien+Cl%C3%ADnica+Vascular/@-25.1033063,-50.1588257,15z/data=!4m2!3m1!1s0x0:0x87bbb1d9a6167648?sa=X&ved=2ahUKEwilrZakooTrAhXfHrkGHYyMAZ4Q_BIwCnoECBMQCA"
              >
                Rua Nestor Guimarães,77 sala 104 Ponta Grossa - PR
              </a>
              <p>Estacionamento Conveniado no subsolo do prédio (BFB)</p>
            </div>
          </div>
          <div className="form">
            <form onSubmit={formik.handleSubmit}>
              <input
                type="text"
                name="nome"
                placeholder="Nome"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nome}
              />
              {formik.touched.nome && formik.errors.nome ? (
                <span>{formik.errors.nome}</span>
              ) : null}

              <input
                type="email"
                name="email"
                placeholder="E-mail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <span>{formik.errors.email}</span>
              ) : null}

              <Input
                type="tel"
                name="telefone"
                mask={
                  formik.values.telefone.length <= 14
                    ? '(99) 9999-9999?'
                    : '(99) 99999-9999'
                }
                formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                maskChar={null}
                placeholder="Telefone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.telefone}
              />
              {formik.touched.telefone && formik.errors.telefone ? (
                <span>{formik.errors.telefone}</span>
              ) : null}

              <textarea
                name="mensagem"
                placeholder="Mensagem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mensagem}
              />
              {formik.touched.mensagem && formik.errors.mensagem ? (
                <span>{formik.errors.mensagem}</span>
              ) : null}
              <div className="submitbtn">
                <button type="submit">Enviar</button>
                <div className={disabledButton ? 'disabled' : ''} />
              </div>
            </form>
          </div>
        </div>
        <footer>
          <p>©2020 Vaskupien. Todos os direitos reservados</p>
          <a
            href="https://agenciaade.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Feito com{' '}
            <img src={require('../../assets/img/heart.svg')} alt="Coração" />{' '}
            por agenciaade.com.br
          </a>
        </footer>
      </div>
    </Contato>
  );
}

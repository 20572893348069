import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Blog from '../pages/Blog';
import Single from '../pages/Blog/Single';
import Teste from '../pages/teste';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/blog" exact component={Blog} />
      <Route path="/blog/:slug" exact component={Single} />
      <Route path="/teste" exact component={Teste} />
    </Switch>
  );
}
